var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", close: "", persistent: "" },
      model: {
        value: _vm.showUnknownSymbolTypeDialog,
        callback: function($$v) {
          _vm.showUnknownSymbolTypeDialog = $$v
        },
        expression: "showUnknownSymbolTypeDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _vm._v(" Unknown symbol type ")
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pa-5 d-flex justify-end gap" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }