var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTasksTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTasksTab,
            callback: function($$v) {
              _vm.showTasksTab = $$v
            },
            expression: "showTasksTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Tasks")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTasksTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTasksTab
                ? _c("TasksTab", {
                    staticClass: "overflow-y-auto",
                    attrs: {
                      globalId: _vm.globalId,
                      selectedMapServiceId: _vm.selectedMapServiceId,
                      objectId: _vm.objectId
                    },
                    on: {
                      "update-task-count": function($event) {
                        return _vm.$emit("update-task-count", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showTicketLogTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showTicketLogTab,
            callback: function($$v) {
              _vm.showTicketLogTab = $$v
            },
            expression: "showTicketLogTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Log")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showTicketLogTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showTicketLogTab
                ? _c("Log", {
                    staticClass: "overflow-y-auto",
                    attrs: {
                      globalId: _vm.globalId,
                      selectedMapServiceId: _vm.selectedMapServiceId,
                      objectId: _vm.objectId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "v-navigation-drawer",
        {
          staticStyle: { "z-index": "101" },
          attrs: {
            app: "",
            right: "",
            permanent: _vm.showDocsTab,
            "hide-overlay": "",
            width: "375px",
            stateless: ""
          },
          model: {
            value: _vm.showDocsTab,
            callback: function($$v) {
              _vm.showDocsTab = $$v
            },
            expression: "showDocsTab"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "rounded-0 d-flex flex-column",
              staticStyle: { height: "100vh" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "my-0 py-1",
                  attrs: { color: "#3F51B5", width: "100%" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 my-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "white--text",
                                  staticStyle: { "font-size": "20px" }
                                },
                                [_vm._v("Documents")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showDocsTab = false
                                      _vm.gisInfoTab = undefined
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" " + _vm._s(_vm.mdiClose) + " ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showDocsTab
                ? _c("DocsTab", {
                    staticClass: "overflow-y-auto",
                    attrs: {
                      globalId: _vm.globalId,
                      selectedMapServiceId: _vm.selectedMapServiceId,
                      objectId: _vm.objectId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm.showGisDataPointFilterDialog
        ? _c("GisDataPointFilterDialog", {
            attrs: {
              showGisDataPointFilterDialog: _vm.showGisDataPointFilterDialog,
              gisDataPoints: _vm.gisDataPoints,
              savedFilterChoices: _vm.filterChoices,
              selectedMapServiceId: _vm.selectedMapServiceId,
              featureItemFields: _vm.featureItemFields
            },
            on: {
              "gis-data-point-filter-close": function($event) {
                _vm.showGisDataPointFilterDialog = false
              },
              "update-filter": _vm.onUpdateFilter
            }
          })
        : _vm._e(),
      _c(
        "section",
        {
          ref: "filterDisplay",
          staticClass: "d-flex justify-space-between flex-wrap"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center px-5" },
            [
              _vm.onMapPage
                ? _c("TableViewDropdown", {
                    staticClass: "pt-4",
                    attrs: { currentSelectedTable: this.newSelectedTable },
                    on: {
                      "selected-table": function($event) {
                        return _vm.$emit("selected-table", $event)
                      }
                    }
                  })
                : _vm._e(),
              _c("v-autocomplete", {
                staticClass: "autocomplete",
                attrs: {
                  items: _vm.layerChoices,
                  "item-text": "label",
                  "item-value": "value",
                  label: "Layer",
                  disabled: _vm.disableLayerSelect,
                  "menu-props": "auto"
                },
                on: { change: _vm.onLayerIdChange },
                model: {
                  value: _vm.selectedMapServiceId,
                  callback: function($$v) {
                    _vm.selectedMapServiceId = $$v
                  },
                  expression: "selectedMapServiceId"
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "px-1 ml-3",
                  attrs: {
                    text: "",
                    color: "#3F51B5",
                    disabled: !_vm.selectedMapServiceId
                  },
                  on: {
                    click: function($event) {
                      _vm.showGisDataPointFilterDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                  _vm._v(" Filter ")
                ],
                1
              ),
              _c("FilterChoicesDisplay", {
                staticClass: "flex-grow-1",
                attrs: { filterChoices: _vm.filterChoices },
                on: { "remove-filter": _vm.removeFilter }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "align-center px-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-1 mx-1",
                  attrs: {
                    text: "",
                    color: "#3F51B5",
                    disabled: !_vm.isOnline || _vm.isDownloading
                  },
                  on: { click: _vm.reload }
                },
                [
                  _vm.isDownloading
                    ? _c("v-progress-circular", {
                        staticClass: "mr-1",
                        attrs: { color: "#3F51B5", size: 15, indeterminate: "" }
                      })
                    : _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "#3F51B5" } },
                        [_vm._v(" " + _vm._s(_vm.mdiRefresh) + " ")]
                      ),
                  _vm._v(" Reload ")
                ],
                1
              ),
              _c("div", { staticClass: "text-caption" }, [
                _vm._v(
                  " Updated: " +
                    _vm._s(
                      _vm._f("formatVariableDate")(_vm.lastUpdated, "hh:mm A")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ]
      ),
      _vm.selectedLayerType === "U"
        ? _c(
            "div",
            [
              _vm.showLoader
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "#3F51B5" }
                      })
                    ],
                    1
                  )
                : _c("v-data-table", {
                    staticClass: "pa-0 ma-0 cursor-pointer",
                    attrs: {
                      headers: _vm.filteredHeaders,
                      items: _vm.filteredMappedGisDataPoints,
                      height: _vm.tableHeight,
                      "item-key": "objectId",
                      "hide-default-footer": "",
                      "footer-props": {
                        "items-per-page-options": [100]
                      },
                      "items-per-page": 100,
                      "calculate-widths": "",
                      "fixed-header": "",
                      "item-class": _vm.getRowClass
                    },
                    on: { "click:row": _vm.onRowClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.menu",
                          fn: function() {
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "#3F51B5"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { dark: "" } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiPlusCircle
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1716732293
                                  )
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        _vm._l(_vm.nonEmptyHeaders, function(
                                          h
                                        ) {
                                          return _c("v-checkbox", {
                                            key: h.value,
                                            staticClass: "py-0 my-0",
                                            attrs: { label: h.text },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.headersEnabled[h.value],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.headersEnabled,
                                                  h.value,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "headersEnabled[h.value]"
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        _vm._l(_vm.filteredHeaders, function(h) {
                          return {
                            key: "item." + h.value,
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm.isRichTextField(item[h.value])
                                  ? _c("div", {
                                      key: h.value,
                                      staticClass: "d-flex align-center",
                                      domProps: {
                                        innerHTML: _vm._s(item[h.value])
                                      }
                                    })
                                  : _c(
                                      "div",
                                      {
                                        key: h.value,
                                        staticClass: "d-flex align-center"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item[h.value]) + " "
                                        )
                                      ]
                                    )
                              ]
                            }
                          }
                        }),
                        {
                          key: "footer",
                          fn: function(ref) {
                            var props = ref.props
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                {
                                  ref: "bottomBar",
                                  staticClass: "d-flex justify-end"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-data-footer",
                                        _vm._g(
                                          {
                                            staticStyle: { border: "none" },
                                            attrs: {
                                              "items-per-page-options": [100],
                                              options: props.options,
                                              pagination: props.pagination
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
            ],
            1
          )
        : _vm.selectedLayerType === "F"
        ? _c(
            "div",
            [
              _vm.showLoader
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "#3F51B5" }
                      })
                    ],
                    1
                  )
                : _c("v-data-table", {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      headers: _vm.filteredFeatureTableHeaders,
                      items: _vm.filteredSelectedFeatureLayerFeatures,
                      "item-key": "OBJECTID",
                      "hide-default-footer": "",
                      "footer-props": {
                        "items-per-page-options": [100]
                      },
                      "items-per-page": 100,
                      "calculate-widths": "",
                      "fixed-header": "",
                      height: _vm.tableHeight,
                      "item-class": _vm.getRowClass
                    },
                    on: { "click:row": _vm.onFeatureRowClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.menu",
                          fn: function() {
                            return [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "#3F51B5"
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { dark: "" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiPlusCircle
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        _vm._l(
                                          _vm.nonEmptyFeatureLayerTableHeaders,
                                          function(h) {
                                            return _c("v-checkbox", {
                                              key: h.value,
                                              staticClass: "py-0 my-0",
                                              attrs: { label: h.text },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .featureLayerTableHeadersEnabled[
                                                    h.value
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.featureLayerTableHeadersEnabled,
                                                    h.value,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "featureLayerTableHeadersEnabled[h.value]"
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        _vm._l(_vm.filteredFeatureTableHeaders, function(h) {
                          return {
                            key: "item." + h.value,
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm.isRichTextField(item[h.value])
                                  ? _c("div", {
                                      key: h.value,
                                      domProps: {
                                        innerHTML: _vm._s(item[h.value])
                                      }
                                    })
                                  : _vm.getEsriFieldType(h.value) ===
                                      "esriFieldTypeDate" && item[h.value]
                                  ? _c("div", { key: h.value }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              new Date(item[h.value])
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("div", { key: h.value }, [
                                      _vm._v(_vm._s(item[h.value]))
                                    ])
                              ]
                            }
                          }
                        }),
                        {
                          key: "footer",
                          fn: function(ref) {
                            var props = ref.props
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                {
                                  ref: "bottomBar",
                                  staticClass: "d-flex justify-end"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-data-footer",
                                        _vm._g(
                                          {
                                            staticStyle: { border: "none" },
                                            attrs: {
                                              "items-per-page-options": [100],
                                              options: props.options,
                                              pagination: props.pagination
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }