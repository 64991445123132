export const getHeaders = (sortable) => {
  const headers = [
    {
      text: "Closed Date",
      align: "start",
      sortable,
      value: "closed_on",
      width: "120px",
    },
    {
      text: "Legal Date",
      align: "start",
      sortable,
      value: "legal_date",
      width: "120px",
    },
    {
      text: "Ticket Number",
      align: "start",
      sortable,
      value: "locate_request_number",
      width: "150px",
    },
    {
      text: "Account",
      align: "start",
      sortable,
      value: "provider_account_name",
      width: "120px",
    },
    {
      text: "Ticket Type",
      align: "start",
      sortable,
      value: "ticket_type",
      width: "120px",
    },
    {
      text: "Work Type",
      align: "start",
      sortable,
      value: "work_type",
      width: "120px",
    },
    {
      text: "Address",
      align: "start",
      sortable,
      value: "street_address",
      width: "200px",
    },
    {
      text: "Assigned To",
      align: "start",
      sortable,
      value: "assigned_to_user_id",
      width: "150px",
    },
  ];
  return headers;
};
