var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "addFeaturePanel" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-0",
          attrs: { dark: "", color: "#3F51B5", height: "56px" }
        },
        [
          _c("v-toolbar-title", [_c("div", [_vm._v("Add Feature")])]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "pa-0 ma-0",
              attrs: { icon: "", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close-feature-pane")
                }
              }
            },
            [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
            1
          )
        ],
        1
      ),
      Array.isArray(_vm.layersWithUpdateCapability)
        ? _c(
            "div",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "calc(100vh - 56px)" }
            },
            _vm._l(_vm.layersWithUpdateCapability, function(layer, index) {
              return _c(
                "div",
                { key: index + "-" + layer.mapServiceId, staticClass: "px-0" },
                [
                  _c(
                    "div",
                    { staticClass: "py-0" },
                    [
                      layer.utiliSyncLayerType === "S"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n              d-flex\n              flex-sm-nowrap flex-wrap\n              justify-space-between\n              align-center\n              pt-2\n              pb-0\n            "
                              },
                              [
                                _c("div", { staticClass: "d-flex" }, [
                                  _c("div", { staticClass: "flex-wrap" }, [
                                    _vm._v(" " + _vm._s(layer.id) + " ")
                                  ])
                                ])
                              ]
                            )
                          ]
                        : layer.utiliSyncLayerType === "F"
                        ? [
                            layer.renderer &&
                            ["unique-value", "class-breaks"].includes(
                              layer.renderer.type
                            )
                              ? [
                                  _c("div", { staticClass: "pa-0 ma-0" }, [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "\n                    d-flex\n                    flex-sm-nowrap flex-wrap\n                    justify-space-between\n                    align-center\n                    pt-2\n                    pb-0\n                  "
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex pl-4" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-wrap font-weight-medium"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        layer.utiliSyncLayerName
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    layer.renderer
                                      ? _c("div", [
                                          layer.renderer.type === "unique-value"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "cursor-pointer"
                                                },
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        layer.renderer
                                                          .uniqueValueInfos,
                                                      viewSymbols: ""
                                                    },
                                                    on: {
                                                      "add-symbol": function(
                                                        $event
                                                      ) {
                                                        return _vm.$emit(
                                                          "add-symbol",
                                                          $event,
                                                          layer
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : layer.renderer.type ===
                                              "class-breaks"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "cursor-pointer"
                                                },
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        layer.renderer
                                                          .classBreakInfos,
                                                      viewSymbols: ""
                                                    },
                                                    on: {
                                                      "add-symbol": function(
                                                        $event
                                                      ) {
                                                        return _vm.$emit(
                                                          "add-symbol",
                                                          $event,
                                                          layer
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              : layer.renderer &&
                                ["simple"].includes(layer.renderer.type)
                              ? [
                                  _c("div", { staticClass: "pa-0 ma-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                  d-flex\n                  flex-sm-nowrap flex-wrap\n                  cursor-pointer\n                  feature_hover\n                "
                                      },
                                      [
                                        layer.renderer
                                          ? _c("SimpleRendererItem", {
                                              attrs: {
                                                itemInfo: layer,
                                                viewSymbols: ""
                                              },
                                              on: {
                                                "add-symbol": function($event) {
                                                  return _vm.$emit(
                                                    "add-symbol",
                                                    $event,
                                                    layer
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : _vm._e()
                          ]
                        : ["L", "U"].includes(layer.utiliSyncLayerType)
                        ? [
                            layer.utilisyncRenderer.renderer_symbols &&
                            layer.utilisyncRenderer.renderer_symbols.length > 1
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                align-center\n                pt-2\n                pb-0\n              "
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex pl-4" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-wrap font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(layer.id) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "pa-0 ma-0" }, [
                                    layer.utilisyncRenderer
                                      ? _c("div", [
                                          [
                                            "unique_value",
                                            "class_break",
                                            "simple"
                                          ].includes(
                                            layer.utilisyncRenderer
                                              .renderer_type
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  _c("RendererItemList", {
                                                    attrs: {
                                                      infos:
                                                        layer.utilisyncRenderer
                                                          .renderer_symbols,
                                                      viewSymbols: ""
                                                    },
                                                    on: {
                                                      "add-symbol": function(
                                                        $event
                                                      ) {
                                                        return _vm.$emit(
                                                          "add-symbol",
                                                          $event,
                                                          layer
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ])
                                ]
                              : layer.utilisyncRenderer.renderer_symbols &&
                                layer.utilisyncRenderer.renderer_symbols
                                  .length === 1
                              ? [
                                  _c("div", { staticClass: "pa-0 ma-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "\n                  d-flex\n                  flex-sm-nowrap flex-wrap\n                  feature_hover\n                  cursor-pointer\n                "
                                      },
                                      [
                                        _c("UtilisyncRendererItem", {
                                          attrs: {
                                            itemInfo:
                                              layer.utilisyncRenderer
                                                .renderer_symbols[0],
                                            layer: layer,
                                            viewSymbols: ""
                                          },
                                          on: {
                                            "add-symbol": function($event) {
                                              return _vm.$emit(
                                                "add-symbol",
                                                $event,
                                                layer
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : _vm._e()
                          ]
                        : [
                            _c("div", { staticClass: "pa-0 ma-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                d-flex\n                flex-sm-nowrap flex-wrap\n                justify-space-between\n                ma-0\n                pa-0\n                align-center\n              ",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "add-symbol",
                                        layer.renderer,
                                        layer
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-sm-nowrap flex-wrap cursor-pointer"
                                    },
                                    [
                                      _c("RendererItem", {
                                        attrs: {
                                          itemInfo: layer.renderer,
                                          viewSymbols: ""
                                        }
                                      }),
                                      _c("div", { staticClass: "flex-wrap" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(layer.utiliSyncLayerName) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ]
                    ],
                    2
                  ),
                  _c("v-divider")
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }