<template>
  <v-dialog
    v-model="showClassBreakValueDialog"
    max-width="600"
    close
    persistent
  >
    <validation-observer ref="filterForm" slim>
      <v-card>
        <v-card-text class="py-5">
          <h2 class="font-weight-regular">Add Site</h2>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Value"
            :rules="{ required: true }"
          >
            <v-text-field
              v-model="classBreakValue"
              clearable
              :error-messages="errors"
              :success="valid"
              label="Value"
              hide-details
            >
            </v-text-field>
          </validation-provider>
        </v-card-text>
        <v-card-actions class="pa-5 d-flex justify-end gap">
          <v-btn text @click="$emit('cancel')"> Cancel </v-btn>
          <v-btn dark color="#3F51B5" @click="$emit('save', classBreakValue)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
export default {
  name: "ClassBreakValueDialog",
  props: {
    showClassBreakValueDialog: Boolean,
  },
  data() {
    return {
      classBreakValue: "",
    };
  },
};
</script>
