var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { staticClass: "elevation-0", attrs: { dark: "", color: "#3F51B5" } },
        [
          _c("v-toolbar-title", [_vm._v("Select a Map")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("select-map-form-close")
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-radio-group",
            {
              on: { change: _vm.onRadioSelect },
              model: {
                value: _vm.selectedMapId,
                callback: function($$v) {
                  _vm.selectedMapId = $$v
                },
                expression: "selectedMapId"
              }
            },
            _vm._l(_vm.mapsWithLocateService, function(m) {
              return _c("v-radio", {
                key: m.map_id,
                attrs: { label: m.name, value: m.map_id }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }