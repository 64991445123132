<template>
  <v-dialog
    v-model="showUnknownSymbolTypeDialog"
    max-width="500"
    close
    persistent
  >
    <v-card>
      <v-card-text class="py-5"> Unknown symbol type </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end gap">
        <v-btn text @click="$emit('cancel')"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UnknownSymbolTypeDialog",
  props: {
    showUnknownSymbolTypeDialog: Boolean,
  },
};
</script>
