<template>
  <section id="addFeaturePanel">
    <v-toolbar dark color="#3F51B5" class="elevation-0" height="56px">
      <v-toolbar-title>
        <div>Add Feature</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon text @click="$emit('close-feature-pane')" class="pa-0 ma-0">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div
      v-if="Array.isArray(layersWithUpdateCapability)"
      style="max-height: calc(100vh - 56px)"
      class="overflow-y-auto"
    >
      <div
        class="px-0"
        v-for="(layer, index) of layersWithUpdateCapability"
        :key="`${index}-${layer.mapServiceId}`"
      >
        <div class="py-0">
          <template v-if="layer.utiliSyncLayerType === 'S'">
            <div
              class="
                d-flex
                flex-sm-nowrap flex-wrap
                justify-space-between
                align-center
                pt-2
                pb-0
              "
            >
              <div class="d-flex">
                <div class="flex-wrap">
                  {{ layer.id }}
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="layer.utiliSyncLayerType === 'F'">
            <template
              v-if="
                layer.renderer &&
                ['unique-value', 'class-breaks'].includes(layer.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div>
                  <div
                    class="
                      d-flex
                      flex-sm-nowrap flex-wrap
                      justify-space-between
                      align-center
                      pt-2
                      pb-0
                    "
                  >
                    <div class="d-flex pl-4">
                      <div class="flex-wrap font-weight-medium">
                        {{ layer.utiliSyncLayerName }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="layer.renderer">
                  <div
                    v-if="layer.renderer.type === 'unique-value'"
                    class="cursor-pointer"
                  >
                    <RendererItemList
                      :infos="layer.renderer.uniqueValueInfos"
                      viewSymbols
                      @add-symbol="$emit('add-symbol', $event, layer)"
                    />
                  </div>

                  <div
                    v-else-if="layer.renderer.type === 'class-breaks'"
                    class="cursor-pointer"
                  >
                    <RendererItemList
                      :infos="layer.renderer.classBreakInfos"
                      viewSymbols
                      @add-symbol="$emit('add-symbol', $event, layer)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                layer.renderer && ['simple'].includes(layer.renderer.type)
              "
            >
              <div class="pa-0 ma-0">
                <div
                  class="
                    d-flex
                    flex-sm-nowrap flex-wrap
                    cursor-pointer
                    feature_hover
                  "
                >
                  <SimpleRendererItem
                    v-if="layer.renderer"
                    :itemInfo="layer"
                    viewSymbols
                    @add-symbol="$emit('add-symbol', $event, layer)"
                  />
                </div>
              </div>
            </template>
          </template>

          <template v-else-if="['L', 'U'].includes(layer.utiliSyncLayerType)">
            <template
              v-if="
                layer.utilisyncRenderer.renderer_symbols &&
                layer.utilisyncRenderer.renderer_symbols.length > 1
              "
            >
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  align-center
                  pt-2
                  pb-0
                "
              >
                <div class="d-flex pl-4">
                  <div class="flex-wrap font-weight-medium">
                    {{ layer.id }}
                  </div>
                </div>
              </div>

              <div class="pa-0 ma-0">
                <div v-if="layer.utilisyncRenderer">
                  <div
                    v-if="
                      ['unique_value', 'class_break', 'simple'].includes(
                        layer.utilisyncRenderer.renderer_type
                      )
                    "
                  >
                    <RendererItemList
                      :infos="layer.utilisyncRenderer.renderer_symbols"
                      viewSymbols
                      @add-symbol="$emit('add-symbol', $event, layer)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                layer.utilisyncRenderer.renderer_symbols &&
                layer.utilisyncRenderer.renderer_symbols.length === 1
              "
            >
              <div class="pa-0 ma-0">
                <div
                  class="
                    d-flex
                    flex-sm-nowrap flex-wrap
                    feature_hover
                    cursor-pointer
                  "
                >
                  <UtilisyncRendererItem
                    :itemInfo="layer.utilisyncRenderer.renderer_symbols[0]"
                    :layer="layer"
                    viewSymbols
                    @add-symbol="$emit('add-symbol', $event, layer)"
                  />
                </div>
              </div>
            </template>
          </template>

          <template v-else>
            <div class="pa-0 ma-0">
              <div
                class="
                  d-flex
                  flex-sm-nowrap flex-wrap
                  justify-space-between
                  ma-0
                  pa-0
                  align-center
                "
                @click="$emit('add-symbol', layer.renderer, layer)"
              >
                <div class="d-flex flex-sm-nowrap flex-wrap cursor-pointer">
                  <RendererItem :itemInfo="layer.renderer" viewSymbols />
                  <div class="flex-wrap">
                    {{ layer.utiliSyncLayerName }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </section>
</template>

<script>
import { mdiClose } from "@mdi/js";
import RendererItem from "@/components/shared/RendererItem";
import RendererItemList from "@/components/shared/RendererItemList";
import UtilisyncRendererItem from "@/components/shared/UtilisyncRendererItem";
import SimpleRendererItem from "@/components/shared/SimpleRendererItem";

export default {
  name: "FeaturePane",
  components: {
    RendererItem,
    RendererItemList,
    UtilisyncRendererItem,
    SimpleRendererItem,
  },
  data() {
    return {
      mdiClose,
      layers: [],
    };
  },
  props: {
    map: Object,
  },
  computed: {
    layersWithUpdateCapability() {
      return this.layers.filter(
        (l) =>
          (l?.utiliSyncLayerType === "F" &&
            l.capabilities?.operations?.supportsUpdate) ||
          l?.utiliSyncLayerType === "U"
      );
    },
  },
  watch: {
    map: {
      immediate: true,
      deep: true,
      async handler() {
        await this.$nextTick();
        if (this.map) {
          this.layers = [...this.map.layers._items].reverse();
        }
      },
    },
  },
};
</script>

<style scoped>
.feature_hover:hover {
  background-color: #ccc;
}
</style>
