export default {
  methods: {
    updateAuthObjWithLastMapOpenedId(lastMapOpenedId) {
      try {
        const auth = JSON.parse(localStorage.getItem("auth"));
        const newAuth = { ...auth, last_map_opened: lastMapOpenedId };
        localStorage.setItem("last-map-opened-id", lastMapOpenedId);
        localStorage.setItem("auth", JSON.stringify(newAuth));
      } catch (error) {
        console.error(error);
      }
    },
  },
};
