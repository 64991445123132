var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "basemap-resize", rawName: "v-basemap-resize" }],
      attrs: { id: "viewDiv" }
    },
    [
      !_vm.tracking
        ? _c(
            "v-snackbar",
            {
              attrs: { timeout: "-1" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function(ref) {
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "#00A9F4", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.stopLocating()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" Close ")]
                        ),
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "#00A9F4" },
                              on: {
                                click: function($event) {
                                  return _vm.startTracking()
                                }
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" Track ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3332624221
              ),
              model: {
                value: _vm.trackSnackbar,
                callback: function($$v) {
                  _vm.trackSnackbar = $$v
                },
                expression: "trackSnackbar"
              }
            },
            [_vm._v(" Track location? ")]
          )
        : _c(
            "v-snackbar",
            {
              attrs: { timeout: "-1" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { color: "#00A9F4", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.stopTracking()
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" Stop ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.tracking,
                callback: function($$v) {
                  _vm.tracking = $$v
                },
                expression: "tracking"
              }
            },
            [_vm._v(" Stop Tracking Location? ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }