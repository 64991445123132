var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          !_vm.myLastMapSet && _vm.isOnline
            ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
            : _vm._e(),
          _c("MapViewTopBar", {
            ref: "mapViewTopBar",
            staticStyle: { "z-index": "5" },
            attrs: { maps: _vm.maps, view: _vm.view },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.showMapDialog,
                callback: function($$v) {
                  _vm.showMapDialog = $$v
                },
                expression: "showMapDialog"
              }
            },
            [
              _c("SelectMapForm", {
                attrs: { mapsWithLocateService: _vm.mapsWithLocateService },
                on: {
                  "select-map-form-map-selected": _vm.onMapSelected,
                  "select-map-form-close": function($event) {
                    return _vm.$router.push("/map")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              staticClass: "elevation-3",
              staticStyle: { "z-index": "100" },
              attrs: {
                app: "",
                "hide-overlay": "",
                width: "375px",
                permanent:
                  _vm.showGisInfoPanel && _vm.$vuetify.breakpoint.smAndUp,
                right: "",
                stateless: _vm.$vuetify.breakpoint.smAndUp
              },
              model: {
                value: _vm.showGisInfoPanel,
                callback: function($$v) {
                  _vm.showGisInfoPanel = $$v
                },
                expression: "showGisInfoPanel"
              }
            },
            [
              _vm.showGisInfoPanel
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column background",
                      staticStyle: { height: "100%" },
                      attrs: { id: "infoPanel" }
                    },
                    [
                      _vm.showGisInfoPanel
                        ? _c("GisInfoTopCard", {
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              globalId: _vm.gisInfoId,
                              objectId: _vm.featureId,
                              attributes: _vm.attributes,
                              sharedSite: _vm.sharedSite,
                              gisInfos: _vm.gisInfos,
                              selectedGisInfoIndex: _vm.selectedGisInfoIndex,
                              showFeatureMenu: ""
                            },
                            on: {
                              prev: _vm.prev,
                              next: _vm.next,
                              "gis-info-panel-close": _vm.onGisInfoPanelClose,
                              "open-expand-panel-dialog": function($event) {
                                _vm.showExpansionPanelDialog = true
                              },
                              "move-feature": _vm.onMoveFeature,
                              "delete-feature": _vm.onDeleteFeature,
                              "edited-site": _vm.createSharedSiteIfNotExist
                            }
                          })
                        : _vm._e(),
                      ["F", "U"].includes(_vm.selectedGisInfoLayerType)
                        ? _c("GisInfoButtonBar", {
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: _vm.featureId,
                              taskCount: _vm.taskCount
                            },
                            on: {
                              "gis-info-button-clicked": function($event) {
                                _vm.gisInfoTab = $event
                              }
                            }
                          })
                        : _vm._e(),
                      ["F", "U"].includes(_vm.selectedGisInfoLayerType)
                        ? _c(
                            "div",
                            { staticClass: "background" },
                            [
                              _vm.showGisInfoPanel
                                ? _c("TicketFormDefDropdown", {
                                    attrs: {
                                      inRightPane: "",
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: +_vm.featureId,
                                      selectedGisInfoObj: _vm.selectedGisInfoObj
                                    },
                                    on: {
                                      "ticket-edit-form-submitted": function(
                                        $event
                                      ) {
                                        _vm.onTicketEditFormSubmitted()
                                        _vm.showUtiliBotDialogOnFormSubmitted()
                                      },
                                      "show-edit-dialog": function($event) {
                                        _vm.openGisInfoPanels = [1]
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _vm.showGisInfoPanel
                        ? _c("SharePublicSite", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.selectedLayer &&
                                  _vm.selectedLayer.site_enabled,
                                expression:
                                  "selectedLayer && selectedLayer.site_enabled"
                              }
                            ],
                            attrs: {
                              selectedMapServiceId: _vm.selectedMapServiceId,
                              objectId: +_vm.featureId,
                              attributes: _vm.rawGisInfoAttributes,
                              sharedSite: _vm.sharedSite,
                              globalId: _vm.gisInfoId
                            },
                            on: {
                              "public-link-created":
                                _vm.createSharedSiteIfNotExist,
                              "removed-sharing": _vm.createSharedSiteIfNotExist,
                              "site-name-saved": _vm.createSharedSiteIfNotExist
                            }
                          })
                        : _vm._e(),
                      _vm.selectedLayer && _vm.selectedLayer.site_enabled
                        ? _c("v-divider")
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1 overflow-y-auto" },
                        [
                          _vm.showGisInfoPanel
                            ? _c("SortedGisInfo", {
                                attrs: {
                                  inRightPane: "",
                                  globalId: _vm.gisInfoId,
                                  selectedMapServiceId:
                                    _vm.selectedMapServiceId,
                                  objectId: +_vm.featureId,
                                  gisInfoAttributes: _vm.gisInfoAttributes
                                },
                                on: {
                                  "utilisync-field-saved": _vm.onGisFieldSaved,
                                  "esri-field-saved": _vm.onGisFieldSaved
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showTasksTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showTasksTab,
                callback: function($$v) {
                  _vm.showTasksTab = $$v
                },
                expression: "showTasksTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0 my-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "font-size": "20px" }
                                    },
                                    [_vm._v("Tasks")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTasksTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTasksTab
                    ? _c("TasksTab", {
                        staticClass: "overflow-y-auto",
                        attrs: {
                          globalId: _vm.gisInfoId,
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          objectId: _vm.featureId
                        },
                        on: {
                          "update-task-count": function($event) {
                            _vm.taskCount = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showTicketLogTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showTicketLogTab,
                callback: function($$v) {
                  _vm.showTicketLogTab = $$v
                },
                expression: "showTicketLogTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0 my-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "font-size": "20px" }
                                    },
                                    [_vm._v("Log")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showTicketLogTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showTicketLogTab
                    ? _c("Log", {
                        staticClass: "overflow-y-auto",
                        attrs: {
                          globalId: _vm.gisInfoId,
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          objectId: _vm.featureId,
                          selectedGisInfoObj: _vm.selectedGisInfoObj
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              staticStyle: { "z-index": "101" },
              attrs: {
                app: "",
                right: "",
                permanent: _vm.showDocsTab,
                "hide-overlay": "",
                width: "375px",
                stateless: ""
              },
              model: {
                value: _vm.showDocsTab,
                callback: function($$v) {
                  _vm.showDocsTab = $$v
                },
                expression: "showDocsTab"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "rounded-0 d-flex flex-column",
                  staticStyle: { height: "100vh" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticClass: "my-0 py-1",
                      attrs: { color: "#3F51B5", width: "100%" }
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0 my-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "white--text",
                                      staticStyle: { "font-size": "20px" }
                                    },
                                    [_vm._v(" Documents ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { icon: "", text: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showDocsTab = false
                                          _vm.gisInfoTab = undefined
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiClose) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showDocsTab
                    ? _c("DocsTab", {
                        staticClass: "overflow-y-auto",
                        attrs: {
                          globalId: _vm.gisInfoId,
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          objectId: +_vm.featureId
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showFeaturePane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showFeaturePane,
                callback: function($$v) {
                  _vm.showFeaturePane = $$v
                },
                expression: "showFeaturePane"
              }
            },
            [
              _vm.showFeaturePane
                ? _c("FeaturePane", {
                    attrs: { map: _vm.map },
                    on: {
                      "close-feature-pane": function($event) {
                        _vm.showFeaturePane = false
                      },
                      "add-symbol": _vm.onAddSymbol
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "container",
              class: {
                "overflow-y-hidden": !_vm.loaded
              },
              attrs: { id: "container" }
            },
            [
              _c(
                "div",
                { attrs: { id: "top" } },
                [
                  _c("EsriMapView", {
                    ref: "esriMapView",
                    attrs: { selectOnMap: _vm.selectOnMap },
                    on: {
                      "pointer-down": _vm.onPointerDownGisInfo,
                      "pointer-clicked-outside": _vm.onPointerClickOutside,
                      "map-created": _vm.onMapCreated,
                      "minimize-ticket-list": _vm.minimizeTicketList,
                      "dragged-extent": _vm.onDraggedExtent,
                      "open-feature-pane": _vm.onOpenFeaturePane,
                      "redraw-layers-finished": _vm.onRedrawerLayersFinished
                    }
                  }),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMaskDiv,
                        expression: "showMaskDiv"
                      }
                    ],
                    style: _vm.maskDivStyles,
                    attrs: { id: "maskDiv" }
                  }),
                  _vm.selectedSymbolToAdd
                    ? _c(
                        "v-btn",
                        {
                          style: {
                            "z-index": 100,
                            position: "absolute",
                            right: "60px",
                            top: "70px"
                          },
                          attrs: { dark: "", color: "#3F51B5" },
                          on: { click: _vm.cancelAddFeature }
                        },
                        [
                          _c("span", { attrs: { color: "white" } }, [
                            _vm._v(" Cancel ")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "bottomPane",
                  staticClass: "overflow-y-auto",
                  attrs: { id: "bottom" }
                },
                [
                  !_vm.split
                    ? _c("div", {
                        staticClass: "gutter gutter-vertical",
                        staticStyle: {
                          height: "15px",
                          position: "relative",
                          "z-index": "4"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-10",
                      class: _vm.showLayerBottomSheet ? "mt-n6" : "mt-n12",
                      staticStyle: { "z-index": "5" },
                      attrs: {
                        elevation: "2",
                        fab: "",
                        absolute: "",
                        "x-small": "",
                        right: ""
                      },
                      on: { click: _vm.onToggleTicketBottomSheet }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.showLayerBottomSheet
                                ? _vm.mdiChevronUp
                                : _vm.mdiChevronDown
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.showBottomCard
                    ? _c(
                        "v-card",
                        {
                          staticClass: "elevation-0 pa-0 ma-0",
                          staticStyle: {
                            "overflow-x": "hidden",
                            height: "100%"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "elevation-0 px-0 pb-0 ma-0" },
                            [
                              _vm.selectedTable === _vm.TABLES.LAYERS
                                ? _c("MapViewListView", {
                                    attrs: {
                                      newSelectedTable: _vm.selectedTable,
                                      view: _vm.view,
                                      map: _vm.map,
                                      onMapPage: _vm.onMapPage,
                                      bottomPaneHeight: _vm.bottomPaneHeight,
                                      progressBarHeight: _vm.progressBarHeight,
                                      highlightedRowIds: _vm.highlightedRowIds,
                                      inMapView: ""
                                    },
                                    on: {
                                      "row-clicked": _vm.handleRowClick,
                                      "feature-row-clicked":
                                        _vm.handleFeatureTableRowClick,
                                      "layer-changed": function($event) {
                                        _vm.highlightSelect = []
                                      },
                                      "reload-finished": function($event) {
                                        return _vm.setIsRedrawLayers(true)
                                      },
                                      "selected-table": function($event) {
                                        _vm.selectedTable = $event
                                      },
                                      "update-task-count": function($event) {
                                        _vm.taskCount = $event
                                      }
                                    }
                                  })
                                : _vm.selectedTable === _vm.TABLES.TASKS
                                ? _c("MapViewTasksView", {
                                    attrs: {
                                      newSelectedTable: _vm.selectedTable,
                                      onMapPage: _vm.onMapPage,
                                      bottomPaneHeight: _vm.bottomPaneHeight,
                                      progressBarHeight: _vm.progressBarHeight,
                                      inMapView: ""
                                    },
                                    on: {
                                      "selected-table": function($event) {
                                        _vm.selectedTable = $event
                                      }
                                    }
                                  })
                                : _vm.selectedTable === _vm.TABLES.ACTION_ITEMS
                                ? _c("MapViewActionItemsView", {
                                    attrs: {
                                      newSelectedTable: _vm.selectedTable,
                                      onMapPage: _vm.onMapPage,
                                      bottomPaneHeight: _vm.bottomPaneHeight,
                                      progressBarHeight: _vm.progressBarHeight,
                                      inMapView: ""
                                    },
                                    on: {
                                      "selected-table": function($event) {
                                        _vm.selectedTable = $event
                                      }
                                    }
                                  })
                                : _vm.selectedTable ===
                                  _vm.TABLES.FORM_SUBMISSION
                                ? _c("MapViewFormSubmissionView", {
                                    attrs: {
                                      newSelectedTable: _vm.selectedTable,
                                      onMapPage: _vm.onMapPage,
                                      bottomPaneHeight: _vm.bottomPaneHeight,
                                      progressBarHeight: _vm.progressBarHeight,
                                      inMapView: ""
                                    },
                                    on: {
                                      "selected-table": function($event) {
                                        _vm.selectedTable = $event
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        {
                          staticClass: "elevation-0 pa-0 ma-0",
                          staticStyle: {
                            "overflow-x": "hidden",
                            height: "100%"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "elevation-0 px-0 pb-0 ma-0 d-flex justify-center"
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { color: "#3F51B5", indeterminate: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ]
          ),
          _vm.showExpansionPanelDialog
            ? _c("ExpandInfoPanelDialog", {
                attrs: {
                  showExpansionPanelDialog: _vm.showExpansionPanelDialog,
                  globalId: _vm.gisInfoId,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  objectId: _vm.featureId,
                  selectedGisInfoObj: _vm.selectedGisInfoObj
                },
                on: {
                  "expand-info-panel-close": function($event) {
                    _vm.showExpansionPanelDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("DeleteFeatureDialog", {
            attrs: { showDeleteFeatureDialog: _vm.showDeleteFeatureDialog },
            on: {
              "cancel-confirm-delete": function($event) {
                _vm.showDeleteFeatureDialog = false
              },
              "confirm-delete": _vm.confirmDeleteFeature
            }
          }),
          _vm.showClassBreakValueDialog
            ? _c("ClassBreakValueDialog", {
                attrs: {
                  showClassBreakValueDialog: _vm.showClassBreakValueDialog
                },
                on: {
                  cancel: function($event) {
                    _vm.showClassBreakValueDialog = false
                  },
                  save: function($event) {
                    _vm.classBreakValue = $event
                    _vm.showClassBreakValueDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("UnknownSymbolTypeDialog", {
            attrs: {
              showUnknownSymbolTypeDialog: _vm.showUnknownSymbolTypeDialog
            },
            on: {
              close: function($event) {
                _vm.showUnknownSymbolTypeDialog = false
              }
            }
          }),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: -1 },
              model: {
                value: _vm.showClickSnackbar,
                callback: function($$v) {
                  _vm.showClickSnackbar = $$v
                },
                expression: "showClickSnackbar"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center px-3"
                },
                [
                  _c("div", [_vm._v("Click to Add")]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center px-0 mx-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "orange",
                            "min-width": 0,
                            width: 0
                          },
                          on: { click: _vm.cancelAddFeature }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: -1 },
              model: {
                value: _vm.showGpsSnackbar,
                callback: function($$v) {
                  _vm.showGpsSnackbar = $$v
                },
                expression: "showGpsSnackbar"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center px-3"
                },
                [
                  _c("div", [
                    _vm._v("Add point to current location using GPS?")
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center px-0 mx-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "orange" },
                          on: { click: _vm.cancelAddFeature }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-0 mx-0",
                          attrs: { text: "", "min-width": 0 },
                          on: {
                            click: function($event) {
                              return _vm.addFeatureWithGeolocation(
                                _vm.selectedSymbolToAdd,
                                _vm.mapServiceOfFeatureBeingAdded
                              )
                            }
                          }
                        },
                        [_vm._v(" Use GPS ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "v-snackbar",
            {
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          { attrs: { text: "" }, on: { click: _vm.viewForm } },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v(" View Form ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.showFormSubmittedSnackbar,
                callback: function($$v) {
                  _vm.showFormSubmittedSnackbar = $$v
                },
                expression: "showFormSubmittedSnackbar"
              }
            },
            [
              _c("v-icon", { attrs: { dark: "" } }, [
                _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
              ]),
              _vm._v(" Success! The form was submitted. ")
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600px",
                persistent: "",
                fullscreen: _vm.isFullScreen
              },
              model: {
                value: _vm.showEditFormDialog,
                callback: function($$v) {
                  _vm.showEditFormDialog = $$v
                },
                expression: "showEditFormDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.showEditFormDialog
                    ? _c("DynamicForm", {
                        attrs: {
                          formDefinition: _vm.dynamicFormProps.formDefinition,
                          existingFormResultIdMap:
                            _vm.dynamicFormProps.existingFormResultIdMap,
                          selectedPdfFileUrl:
                            _vm.dynamicFormProps.selectedPdfFileUrl,
                          canEdit: _vm.dynamicFormProps.canEdit,
                          alreadySubmittedFinalOnline:
                            _vm.dynamicFormProps.alreadySubmittedFinalOnline,
                          globalId: _vm.dynamicFormProps.globalId,
                          objectId: _vm.dynamicFormProps.objectId,
                          selectedMapServiceId:
                            _vm.dynamicFormProps.selectedMapServiceId,
                          taskId: _vm.dynamicFormProps.taskId
                        },
                        on: {
                          "ticket-edit-form-close-button-click": function(
                            $event
                          ) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-close": function($event) {
                            _vm.showEditFormDialog = false
                          },
                          "ticket-edit-form-submitted": function($event) {
                            _vm.showEditFormDialog = false
                            _vm.showUtiliBotDialogOnFormSubmitted()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.showAuthExpiredDialog
            ? _c("AuthExpiredDialog", {
                attrs: { showAuthExpiredDialog: _vm.showAuthExpiredDialog },
                on: {
                  "portal-login-success": function($event) {
                    _vm.showAuthExpiredDialog = false
                  },
                  "auth-expired-dialog-close": function($event) {
                    _vm.showAuthExpiredDialog = false
                  }
                }
              })
            : _vm._e(),
          _vm.showAfterFormSubmissionUtilibotDialog
            ? _c("UtilibotDialog", {
                attrs: {
                  showUtilibotDialog: _vm.showAfterFormSubmissionUtilibotDialog,
                  formResultId: _vm.formSubmitted.formResultId,
                  openedAfterFormSubmission: ""
                },
                on: {
                  "utilibot-dialog-close": function($event) {
                    _vm.showAfterFormSubmissionUtilibotDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("ApplyEditResultDialog", {
            attrs: {
              showApplyEditResultDialog: _vm.showApplyEditResultDialog,
              errorMessage: _vm.updateResultErrorMessage
            },
            on: {
              close: function($event) {
                _vm.showApplyEditResultDialog = false
              }
            }
          }),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: -1 },
              model: {
                value: _vm.showMoveFeatureSnackbar,
                callback: function($$v) {
                  _vm.showMoveFeatureSnackbar = $$v
                },
                expression: "showMoveFeatureSnackbar"
              }
            },
            [
              _c(
                "section",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c("div", [_vm._v("Move feature.")]),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { text: "" },
                          on: { click: _vm.cancelMoveFeature }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { text: "" },
                          on: { click: _vm.confirmMoveFeature }
                        },
                        [_vm._v(" Save ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 3000 },
              model: {
                value: _vm.showFeatureLayerItemSuccessSnacbkbar,
                callback: function($$v) {
                  _vm.showFeatureLayerItemSuccessSnacbkbar = $$v
                },
                expression: "showFeatureLayerItemSuccessSnacbkbar"
              }
            },
            [
              _c(
                "section",
                {
                  staticClass: "d-flex align-center justify-space-between",
                  staticStyle: { width: "100%" }
                },
                [
                  _vm._v(" Success! Added feature "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.showFeatureLayerItemSuccessSnacbkbar = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(_vm._s(_vm.mdiClose))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }