var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", close: "", persistent: "" },
      model: {
        value: _vm.showClassBreakValueDialog,
        callback: function($$v) {
          _vm.showClassBreakValueDialog = $$v
        },
        expression: "showClassBreakValueDialog"
      }
    },
    [
      _c(
        "validation-observer",
        { ref: "filterForm", attrs: { slim: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                { staticClass: "py-5" },
                [
                  _c("h2", { staticClass: "font-weight-regular" }, [
                    _vm._v("Add Site")
                  ]),
                  _c("validation-provider", {
                    attrs: { name: "Value", rules: { required: true } },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var valid = ref.valid
                          return [
                            _c("v-text-field", {
                              attrs: {
                                clearable: "",
                                "error-messages": errors,
                                success: valid,
                                label: "Value",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.classBreakValue,
                                callback: function($$v) {
                                  _vm.classBreakValue = $$v
                                },
                                expression: "classBreakValue"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-5 d-flex justify-end gap" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("save", _vm.classBreakValue)
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }