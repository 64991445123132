<template>
  <v-card>
    <v-toolbar dark color="#3F51B5" class="elevation-0">
      <v-toolbar-title>Select a Map</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit(`select-map-form-close`)">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-radio-group v-model="selectedMapId" @change="onRadioSelect">
        <v-radio
          v-for="m of mapsWithLocateService"
          :key="m.map_id"
          :label="m.name"
          :value="m.map_id"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mdiClose } from "@mdi/js";

export default {
  props: {
    mapsWithLocateService: Array,
  },
  data() {
    return {
      selectedMapId: undefined,
      mdiClose,
    };
  },
  computed: {
    ...mapGetters(["mapIdSelected"]),
  },
  methods: {
    onRadioSelect() {
      this.$emit("select-map-form-map-selected", this.selectedMapId);
    },
    getSelectedMap() {
      this.selectedMapId = localStorage.getItem("last-map-opened-id");
      this.setSelectedMapId(this.selectedMapId);
    },
    ...mapMutations(["setSelectedMapId"]),
  },
  beforeMount() {
    this.getSelectedMap();
  },
  watch: {
    mapIdSelected(val) {
      this.selectedMapId = val;
    },
  },
};
</script>
