var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { staticClass: "white--text py-0 my-0 top-bar", attrs: { height: "56px" } },
    [
      _c(
        "v-app-bar-nav-icon",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.toggleMenu(!_vm.isMenuOpen)
                }
              }
            },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    color: "orange",
                    content: _vm.numUnsubmittedTickets,
                    overlap: "",
                    value: _vm.numUnsubmittedTickets > 0
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-toolbar-title",
        {
          staticClass: "d-flex justify-space-between px-3",
          staticStyle: { width: "100%" }
        },
        [
          _c(
            "v-toolbar-items",
            { staticStyle: { height: "calc(56px * 0.9)" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-end" },
                [
                  _c("v-select", {
                    staticClass: "map-dropdown",
                    staticStyle: { "min-width": "70px" },
                    style: {
                      "max-width": _vm.$vuetify.breakpoint.xsOnly
                        ? "180px"
                        : "250px"
                    },
                    attrs: {
                      color: "white",
                      dark: "",
                      items: _vm.mapChoices,
                      "item-text": "label",
                      "item-value": "value",
                      label: "Map",
                      "hide-details": ""
                    },
                    on: { change: _vm.onMapIdChange },
                    model: {
                      value: _vm.selectedMapId,
                      callback: function($$v) {
                        _vm.selectedMapId = $$v
                      },
                      expression: "selectedMapId"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "", color: "white" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.mdiDotsVertical))
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "pa-0 ma-0" },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "py-1 px-4 mx-0",
                          on: { click: _vm.saveMapDefaultExtents }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(" " + _vm._s(_vm.mdiMap) + " ")
                          ]),
                          _vm._v(" Save Current Extent as Default ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-toolbar-items",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    dot: "",
                    color: "orange",
                    overlap: "",
                    value: _vm.unreadNotifications
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("show-notifications")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiBell) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }