<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0">
      <v-progress-linear indeterminate v-if="!myLastMapSet && isOnline">
      </v-progress-linear>

      <MapViewTopBar
        :maps="maps"
        @show-notifications="onShowNotifications"
        :view="view"
        ref="mapViewTopBar"
        style="z-index: 5"
      />

      <v-dialog v-model="showMapDialog" max-width="500">
        <SelectMapForm
          @select-map-form-map-selected="onMapSelected"
          @select-map-form-close="$router.push('/map')"
          :mapsWithLocateService="mapsWithLocateService"
        />
      </v-dialog>

      <v-navigation-drawer
        app
        v-model="showGisInfoPanel"
        style="z-index: 100"
        hide-overlay
        width="375px"
        :permanent="showGisInfoPanel && $vuetify.breakpoint.smAndUp"
        right
        :stateless="$vuetify.breakpoint.smAndUp"
        class="elevation-3"
      >
        <div
          class="d-flex flex-column background"
          style="height: 100%"
          v-if="showGisInfoPanel"
          id="infoPanel"
        >
          <GisInfoTopCard
            v-if="showGisInfoPanel"
            :selectedMapServiceId="selectedMapServiceId"
            :globalId="gisInfoId"
            :objectId="featureId"
            :attributes="attributes"
            :sharedSite="sharedSite"
            :gisInfos="gisInfos"
            :selectedGisInfoIndex="selectedGisInfoIndex"
            showFeatureMenu
            @prev="prev"
            @next="next"
            @gis-info-panel-close="onGisInfoPanelClose"
            @open-expand-panel-dialog="showExpansionPanelDialog = true"
            @move-feature="onMoveFeature"
            @delete-feature="onDeleteFeature"
            @edited-site="createSharedSiteIfNotExist"
          />

          <GisInfoButtonBar
            @gis-info-button-clicked="gisInfoTab = $event"
            v-if="['F', 'U'].includes(selectedGisInfoLayerType)"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="featureId"
            :taskCount="taskCount"
          />

          <div
            class="background"
            v-if="['F', 'U'].includes(selectedGisInfoLayerType)"
          >
            <TicketFormDefDropdown
              v-if="showGisInfoPanel"
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="+featureId"
              :selectedGisInfoObj="selectedGisInfoObj"
              @ticket-edit-form-submitted="
                onTicketEditFormSubmitted();
                showUtiliBotDialogOnFormSubmitted();
              "
              @show-edit-dialog="openGisInfoPanels = [1]"
            />
          </div>

          <v-divider></v-divider>

          <SharePublicSite
            v-if="showGisInfoPanel"
            v-show="selectedLayer && selectedLayer.site_enabled"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="+featureId"
            :attributes="rawGisInfoAttributes"
            :sharedSite="sharedSite"
            :globalId="gisInfoId"
            @public-link-created="createSharedSiteIfNotExist"
            @removed-sharing="createSharedSiteIfNotExist"
            @site-name-saved="createSharedSiteIfNotExist"
          />
          <v-divider
            v-if="selectedLayer && selectedLayer.site_enabled"
          ></v-divider>

          <div class="flex-grow-1 overflow-y-auto">
            <SortedGisInfo
              v-if="showGisInfoPanel"
              inRightPane
              :globalId="gisInfoId"
              :selectedMapServiceId="selectedMapServiceId"
              :objectId="+featureId"
              :gisInfoAttributes="gisInfoAttributes"
              @utilisync-field-saved="onGisFieldSaved"
              @esri-field-saved="onGisFieldSaved"
            />
          </div>
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showTasksTab"
        right
        :permanent="showTasksTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0">
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text" style="font-size: 20px">Tasks</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTasksTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <TasksTab
            class="overflow-y-auto"
            v-if="showTasksTab"
            :globalId="gisInfoId"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="featureId"
            @update-task-count="taskCount = $event"
          />
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showTicketLogTab"
        right
        :permanent="showTicketLogTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0">
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text" style="font-size: 20px">Log</div>
                  <v-btn
                    icon
                    text
                    @click="
                      showTicketLogTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <Log
            class="overflow-y-auto"
            :globalId="gisInfoId"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="featureId"
            :selectedGisInfoObj="selectedGisInfoObj"
            v-if="showTicketLogTab"
          />
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        app
        v-model="showDocsTab"
        right
        :permanent="showDocsTab"
        style="z-index: 101"
        hide-overlay
        width="375px"
        stateless
      >
        <div class="rounded-0 d-flex flex-column" style="height: 100vh">
          <v-list color="#3F51B5" class="my-0 py-1" width="100%">
            <v-list-item>
              <v-list-item-content class="py-0 my-0">
                <div class="d-flex justify-space-between align-center">
                  <div class="white--text" style="font-size: 20px">
                    Documents
                  </div>
                  <v-btn
                    icon
                    text
                    @click="
                      showDocsTab = false;
                      gisInfoTab = undefined;
                    "
                    style="font-size: 12px"
                  >
                    <v-icon color="white">
                      {{ mdiClose }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <DocsTab
            class="overflow-y-auto"
            v-if="showDocsTab"
            :globalId="gisInfoId"
            :selectedMapServiceId="selectedMapServiceId"
            :objectId="+featureId"
          />
        </div>
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
          'background-color': '#fafafa',
        }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showFeaturePane"
        right
        :permanent="showFeaturePane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
          'background-color': '#fafafa',
        }"
      >
        <FeaturePane
          @close-feature-pane="showFeaturePane = false"
          @add-symbol="onAddSymbol"
          :map="map"
          v-if="showFeaturePane"
        />
      </v-navigation-drawer>

      <div
        id="container"
        ref="container"
        :class="{
          'overflow-y-hidden': !loaded,
        }"
      >
        <div id="top">
          <EsriMapView
            @pointer-down="onPointerDownGisInfo"
            @pointer-clicked-outside="onPointerClickOutside"
            @map-created="onMapCreated"
            @minimize-ticket-list="minimizeTicketList"
            @dragged-extent="onDraggedExtent"
            @open-feature-pane="onOpenFeaturePane"
            @redraw-layers-finished="onRedrawerLayersFinished"
            :selectOnMap="selectOnMap"
            ref="esriMapView"
          />

          <div id="maskDiv" :style="maskDivStyles" v-show="showMaskDiv"></div>

          <v-btn
            dark
            color="#3F51B5"
            v-if="selectedSymbolToAdd"
            :style="{
              'z-index': 100,
              position: 'absolute',
              right: '60px',
              top: '70px',
            }"
            @click="cancelAddFeature"
          >
            <span color="white"> Cancel </span>
          </v-btn>
        </div>
        <div id="bottom" ref="bottomPane" class="overflow-y-auto">
          <div
            class="gutter gutter-vertical"
            style="height: 15px; position: relative; z-index: 4"
            v-if="!split"
          ></div>

          <v-btn
            elevation="2"
            fab
            absolute
            x-small
            right
            @click="onToggleTicketBottomSheet"
            class="mr-10"
            style="z-index: 5"
            :class="showLayerBottomSheet ? 'mt-n6' : 'mt-n12'"
          >
            <v-icon>
              {{ !showLayerBottomSheet ? mdiChevronUp : mdiChevronDown }}
            </v-icon>
          </v-btn>

          <v-card
            class="elevation-0 pa-0 ma-0"
            style="overflow-x: hidden; height: 100%"
            v-if="showBottomCard"
          >
            <v-card-text class="elevation-0 px-0 pb-0 ma-0">
              <MapViewListView
                @row-clicked="handleRowClick"
                @feature-row-clicked="handleFeatureTableRowClick"
                @layer-changed="highlightSelect = []"
                @reload-finished="setIsRedrawLayers(true)"
                @selected-table="selectedTable = $event"
                :newSelectedTable="selectedTable"
                :view="view"
                :map="map"
                :onMapPage="onMapPage"
                :bottomPaneHeight="bottomPaneHeight"
                :progressBarHeight="progressBarHeight"
                :highlightedRowIds="highlightedRowIds"
                inMapView
                v-if="selectedTable === TABLES.LAYERS"
                @update-task-count="taskCount = $event"
              />
              <MapViewTasksView
                @selected-table="selectedTable = $event"
                :newSelectedTable="selectedTable"
                :onMapPage="onMapPage"
                :bottomPaneHeight="bottomPaneHeight"
                :progressBarHeight="progressBarHeight"
                inMapView
                v-else-if="selectedTable === TABLES.TASKS"
              />
              <MapViewActionItemsView
                @selected-table="selectedTable = $event"
                :newSelectedTable="selectedTable"
                :onMapPage="onMapPage"
                :bottomPaneHeight="bottomPaneHeight"
                :progressBarHeight="progressBarHeight"
                inMapView
                v-else-if="selectedTable === TABLES.ACTION_ITEMS"
              />
              <MapViewFormSubmissionView
                @selected-table="selectedTable = $event"
                :newSelectedTable="selectedTable"
                :onMapPage="onMapPage"
                :bottomPaneHeight="bottomPaneHeight"
                :progressBarHeight="progressBarHeight"
                inMapView
                v-else-if="selectedTable === TABLES.FORM_SUBMISSION"
              />
            </v-card-text>
          </v-card>
          <v-card
            class="elevation-0 pa-0 ma-0"
            style="overflow-x: hidden; height: 100%"
            v-else
          >
            <v-card-text
              class="elevation-0 px-0 pb-0 ma-0 d-flex justify-center"
            >
              <v-progress-circular
                color="#3F51B5"
                indeterminate
              ></v-progress-circular>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <ExpandInfoPanelDialog
        v-if="showExpansionPanelDialog"
        :showExpansionPanelDialog="showExpansionPanelDialog"
        :globalId="gisInfoId"
        :selectedMapServiceId="selectedMapServiceId"
        :objectId="featureId"
        :selectedGisInfoObj="selectedGisInfoObj"
        @expand-info-panel-close="showExpansionPanelDialog = false"
      />

      <DeleteFeatureDialog
        :showDeleteFeatureDialog="showDeleteFeatureDialog"
        @cancel-confirm-delete="showDeleteFeatureDialog = false"
        @confirm-delete="confirmDeleteFeature"
      />

      <ClassBreakValueDialog
        v-if="showClassBreakValueDialog"
        :showClassBreakValueDialog="showClassBreakValueDialog"
        @cancel="showClassBreakValueDialog = false"
        @save="
          classBreakValue = $event;
          showClassBreakValueDialog = false;
        "
      />

      <UnknownSymbolTypeDialog
        :showUnknownSymbolTypeDialog="showUnknownSymbolTypeDialog"
        @close="showUnknownSymbolTypeDialog = false"
      />

      <v-snackbar v-model="showClickSnackbar" :timeout="-1">
        <div class="d-flex justify-space-between align-center px-3">
          <div>Click to Add</div>
          <div class="d-flex align-center px-0 mx-0">
            <v-btn
              text
              @click="cancelAddFeature"
              color="orange"
              :min-width="0"
              :width="0"
            >
              Cancel
            </v-btn>
          </div>
        </div>
      </v-snackbar>

      <v-snackbar v-model="showGpsSnackbar" :timeout="-1">
        <div class="d-flex justify-space-between align-center px-3">
          <div>Add point to current location using GPS?</div>
          <div class="d-flex align-center px-0 mx-0">
            <v-btn text @click="cancelAddFeature" color="orange">
              Cancel
            </v-btn>

            <v-btn
              class="px-0 mx-0"
              text
              @click="
                addFeatureWithGeolocation(
                  selectedSymbolToAdd,
                  mapServiceOfFeatureBeingAdded
                )
              "
              :min-width="0"
            >
              Use GPS
            </v-btn>
          </div>
        </div>
      </v-snackbar>

      <v-snackbar v-model="showFormSubmittedSnackbar">
        <v-icon dark>
          {{ mdiInformation }}
        </v-icon>
        Success! The form was submitted.

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="viewForm"> View Form </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        v-model="showEditFormDialog"
        max-width="600px"
        persistent
        :fullscreen="isFullScreen"
      >
        <v-card>
          <DynamicForm
            :formDefinition="dynamicFormProps.formDefinition"
            :existingFormResultIdMap="dynamicFormProps.existingFormResultIdMap"
            :selectedPdfFileUrl="dynamicFormProps.selectedPdfFileUrl"
            :canEdit="dynamicFormProps.canEdit"
            :alreadySubmittedFinalOnline="
              dynamicFormProps.alreadySubmittedFinalOnline
            "
            :globalId="dynamicFormProps.globalId"
            :objectId="dynamicFormProps.objectId"
            :selectedMapServiceId="dynamicFormProps.selectedMapServiceId"
            :taskId="dynamicFormProps.taskId"
            @ticket-edit-form-close-button-click="showEditFormDialog = false"
            @ticket-edit-form-close="showEditFormDialog = false"
            @ticket-edit-form-submitted="
              showEditFormDialog = false;
              showUtiliBotDialogOnFormSubmitted();
            "
            v-if="showEditFormDialog"
          />
        </v-card>
      </v-dialog>

      <AuthExpiredDialog
        v-if="showAuthExpiredDialog"
        :showAuthExpiredDialog="showAuthExpiredDialog"
        @portal-login-success="showAuthExpiredDialog = false"
        @auth-expired-dialog-close="showAuthExpiredDialog = false"
      />

      <UtilibotDialog
        v-if="showAfterFormSubmissionUtilibotDialog"
        :showUtilibotDialog="showAfterFormSubmissionUtilibotDialog"
        :formResultId="formSubmitted.formResultId"
        openedAfterFormSubmission
        @utilibot-dialog-close="showAfterFormSubmissionUtilibotDialog = false"
      />

      <ApplyEditResultDialog
        :showApplyEditResultDialog="showApplyEditResultDialog"
        :errorMessage="updateResultErrorMessage"
        @close="showApplyEditResultDialog = false"
      />

      <v-snackbar v-model="showMoveFeatureSnackbar" :timeout="-1">
        <section class="d-flex justify-space-between align-center">
          <div>Move feature.</div>

          <div class="d-flex align-center">
            <v-btn class="pa-0 ma-0" text @click="cancelMoveFeature">
              Cancel
            </v-btn>
            <v-btn class="pa-0 ma-0" text @click="confirmMoveFeature">
              Save
            </v-btn>
          </div>
        </section>
      </v-snackbar>

      <v-snackbar
        :timeout="3000"
        v-model="showFeatureLayerItemSuccessSnacbkbar"
      >
        <section
          class="d-flex align-center justify-space-between"
          style="width: 100%"
        >
          Success! Added feature
          <v-btn
            icon
            dark
            @click="showFeatureLayerItemSuccessSnacbkbar = false"
          >
            <v-icon dark>{{ mdiClose }}</v-icon>
          </v-btn>
        </section>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import EsriMapView from "@/components/EsriMapView.vue";
import MapViewTopBar from "@/components/app/MapViewTopBar.vue";
import SelectMapForm from "@/components/mapView/SelectMapForm.vue";
import TicketFormDefDropdown from "@/components/tickets/TicketFormDefDropdown.vue";
import ExpandInfoPanelDialog from "@/components/mapView/ExpandInfoPanelDialog.vue";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { mapGetters, mapMutations } from "vuex";
import {
  mdiClose,
  mdiMinus,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronUp,
  mdiInformation,
} from "@mdi/js";
import mapLayerMixin, { setGraphicSymbol } from "@/mixins/mapLayerMixin";
import { getHeaders } from "@/mixins/ticketHeaders";
import Split from "split.js";
import GisInfoTopCard from "@/components/mapView/GisInfoTopCard";
import gisInfoMixin from "@/mixins/gisInfoMixin";
import updateLastMapMixin from "@/mixins/updateLastMapMixin";
import sleep from "@/mixins/sleep";
import Log from "@/components/tickets/Log.vue";
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import GisInfoButtonBar from "@/components/mapView/GisInfoButtonBar.vue";
import { getGisInfoObjectId, getGisInfoGlobalId } from "@/mixins/getId";
import MapViewListView from "@/components/mapView/MapViewListView";
import MapViewActionItemsView from "@/components/mapView/MapViewActionItemsView";
import MapViewFormSubmissionView from "@/components/mapView/MapViewFormSubmissionView";
import MapViewTasksView from "@/components/mapView/MapViewTasksView";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import { db } from "@/mixins/utilisync-db";
import signOutMixin from "@/mixins/signOutMixin";
import TABS from "@/constants/tabs";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import Dexie from "dexie";
import usetifulMixin from "@/mixins/usetifulMixin";
import FeaturePane from "@/components/mapView/FeaturePane";
import { loadModules } from "esri-loader";
import DeleteFeatureDialog from "@/components/mapView/DeleteFeatureDialog";
import ClassBreakValueDialog from "@/components/mapView/ClassBreakValueDialog";
import UnknownSymbolTypeDialog from "@/components/mapView/UnknownSymbolTypeDialog";
import SharePublicSite from "@/components/app/SharePublicSite";
import mapViewSharedSiteMixin from "@/mixins/mapViewSharedSiteMixin";
import DynamicForm from "@/components/tickets/ticket-edit-form/DynamicForm";
import moment from "moment";
import fullScreenCheckMixin from "@/mixins/fullScreenCheckMixin";
import getActiveTaskCountMixin from "@/mixins/getActiveTaskCountMixin";
import getFormResultMixin from "@/mixins/getFormResultMixin";
import AuthExpiredDialog from "@/components/app/AuthExpiredDialog";
import validateEsriTokenMixin from "@/mixins/validateEsriTokenMixin";
import SortedGisInfo from "@/components/mapView/SortedGisInfo";
import notificationPaneMixin from "@/mixins/notificationPaneMixin";
import showUtiliBotDialogOnFormSubmittedMixin from "@/mixins/showUtiliBotDialogOnFormSubmittedMixin";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";
import ApplyEditResultDialog from "@/components/app/ApplyEditResultDialog";

const APIURL = process.env.VUE_APP_API_URL;

const modulo = (n, m) => {
  return ((n % m) + m) % m;
};

const TABLES = {
  LAYERS: "layers",
  TASKS: "tasks",
  ACTION_ITEMS: "action-items",
  FORM_SUBMISSION: "form-submission",
};

const TABLE_CHOICES = [
  { label: "Layers", value: TABLES.LAYERS },
  { label: "Tasks", value: TABLES.TASKS },
  { label: "Action items", value: TABLES.ACTION_ITEMS },
  { label: "Form Submissions", value: TABLES.FORM_SUBMISSION },
];

export default {
  name: "Home",
  components: {
    EsriMapView,
    MapViewTopBar,
    SelectMapForm,
    TicketFormDefDropdown,
    GisInfoTopCard,
    Log,
    NotificationsPane,
    DocsTab,
    GisInfoButtonBar,
    TasksTab,
    ExpandInfoPanelDialog,
    MapViewListView,
    MapViewActionItemsView,
    MapViewFormSubmissionView,
    MapViewTasksView,
    FeaturePane,
    DeleteFeatureDialog,
    ClassBreakValueDialog,
    UnknownSymbolTypeDialog,
    SharePublicSite,
    DynamicForm,
    AuthExpiredDialog,
    SortedGisInfo,
    UtilibotDialog,
    ApplyEditResultDialog,
  },
  mixins: [
    mapLayerMixin,
    gisInfoMixin,
    updateLastMapMixin,
    downloadDataMixin,
    bulkDownloadDataMixin,
    signOutMixin,
    networkStatusMixin,
    usetifulMixin,
    mapViewSharedSiteMixin,
    fullScreenCheckMixin,
    getActiveTaskCountMixin,
    getFormResultMixin,
    validateEsriTokenMixin,
    notificationPaneMixin,
    showUtiliBotDialogOnFormSubmittedMixin,
  ],
  computed: {
    selectedLayerUtiliSyncFields() {
      return this.selectedLayer?.display_utilisync_fields;
    },
    selectedLayer() {
      const selectedLayer = this.layers?.find((l) => {
        return this.selectedMapServiceId === l.map_service_id;
      });
      return { ...selectedLayer };
    },
    attributes() {
      return this.selectedGisInfo?.graphic?.attributes ?? {};
    },
    rawGisInfoAttributes() {
      if (!this.selectedGisInfo?.graphic?.attributes) {
        return {};
      }
      const { fields } = this.selectedGisInfo?.graphic?.layer ?? {};
      if (!Array.isArray(fields)) {
        if (
          this.selectedGisInfo.graphic?.sourceLayer?.layer
            ?.utiliSyncLayerType === "S"
        ) {
          return this.selectedGisInfo?.graphic?.attributes;
        } else {
          return {};
        }
      }

      const entries = fields.map(({ name: key }) => {
        const value = this.selectedGisInfo.graphic.attributes[key];

        return [key, value];
      });
      return Object.fromEntries(entries);
    },
    gisInfoAttributes() {
      if (!this.selectedGisInfo?.graphic?.attributes) {
        return {};
      }
      const { fields } = this.selectedGisInfo?.graphic?.layer ?? {};
      if (!Array.isArray(fields)) {
        if (
          this.selectedGisInfo.graphic?.sourceLayer?.layer
            ?.utiliSyncLayerType === "S"
        ) {
          return this.selectedGisInfo?.graphic?.attributes;
        } else {
          return {};
        }
      }

      const entries = fields.map(({ name: key }) => {
        const value = this.selectedGisInfo.graphic.attributes[key];

        return [this.findAliasByName(fields, key), value];
      });
      return Object.fromEntries(entries);
    },
    selectedGisInfoObj() {
      return this.gisInfos?.[this.selectedGisInfoIndex];
    },
    gisInfoId() {
      return getGisInfoGlobalId(this.selectedGisInfoObj);
    },
    featureId() {
      return +getGisInfoObjectId(this.selectedGisInfoObj);
    },
    selectedGisInfoLayerType() {
      return this.selectedGisInfo?.graphic?.layer?.utiliSyncLayerType;
    },
    computedHeaders() {
      const headers = getHeaders(this.$route.query.ticketStatus !== "closed");
      if (this.$route.query.ticketStatus === "closed") {
        return headers;
      }
      return headers.filter(({ value }) => {
        return value !== "closed_on";
      });
    },
    closedTicketPageCount() {
      return Math.ceil(this.totalResults / this.options.itemsPerPage);
    },
    snackbarTotalResults() {
      return Math.min(this.options.itemsPerPage, this.totalResults);
    },
    selectedMapServiceId() {
      return this.gisInfos?.[this.selectedGisInfoIndex]?.layer?.mapServiceId;
    },
    serverItemsLength() {
      const { ticketStatus } = this.$route.query;
      if (ticketStatus === "closed") {
        if (this.totalResults === this.allClosedTickets.length) {
          return undefined;
        }
        return this.totalResults;
      }
      return undefined;
    },
    ...mapGetters([
      "mapIdSelected",
      "isReloadMap",
      "isMapChanged",
      "formSubmitted",
      "dynamicFormProps",
      "myLastMapSet",
    ]),
  },
  data() {
    return {
      TABLE_CHOICES,
      TABLES,
      selectedTable: TABLES.LAYERS,
      showMapDialog: false,
      mapsWithLocateService: [],
      mdiClose,
      map: undefined,
      view: undefined,
      totalResults: 0,
      options: {
        itemsPerPage: 100,
      },
      showSearchDialog: false,
      showTicketBottomSheet: false,
      showDrawer: false,
      mdiMinus,
      lastUpdated: new Date(),
      showLayerBottomSheet: false,
      users: [],
      split: undefined,
      showLayerDrawer: false,
      showGisInfoPanel: false,
      gisInfos: [],
      selectedGisInfoIndex: 0,
      mdiChevronLeft,
      mdiChevronRight,
      mdiChevronDown,
      mdiChevronUp,
      mdiInformation,
      maps: [],
      currentPolygonGraphic: undefined,
      openPanels: [1],
      openGisInfoPanels: [1],
      selectOnMap: false,
      currentPage: 1,
      currentItemsPerPage: 100,
      currentPageItems: [],
      pagination: {
        current: 1,
      },
      showNotificationPane: false,
      loaded: false,
      mapAreaSelectedChanged: false,
      gisInfoTab: undefined,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      maskDivStyles: {
        position: "absolute",
        background: "rgba(255, 51, 0, 0.1)",
        border: "2px dashed rgb(255, 51, 0)",
        "z-index": 500,
      },
      showMaskDiv: false,
      formDataOfFormBeingFilled: {},
      showEditFormDialog: false,
      selectedMapArea: undefined,
      showExpansionPanelDialog: false,
      showFeaturePane: false,
      selectedSymbolToAdd: undefined,
      selectedFeatureToMove: undefined,
      selectedFeatureToDelete: undefined,
      gisDataPointBeingAdded: undefined,
      graphicBeingAdded: undefined,
      mapServiceOfFeatureBeingAdded: undefined,
      showDeleteFeatureDialog: false,
      objectIdOfFeatureToDelete: undefined,
      geolocationStatus: {},
      rendererInfoBeingAdded: undefined,
      coordinatesOfFeatureBeingAdded: { longitude: 0, latitude: 0 },
      classBreakValue: "",
      showClassBreakValueDialog: false,
      showUnknownSymbolTypeDialog: false,
      featureLayerFeaturesBeingAdded: undefined,
      featureItemFields: [],
      graphicsforPolyLineOrPolygon: [],
      draw: undefined,
      showGpsSnackbar: false,
      showClickSnackbar: false,
      showBottomCard: true,
      selectedSite: {},
      onMapPage: true,
      bottomPaneHeight: 0,
      progressBarHeight: 0,
      taskCount: undefined,
      layers: [],
      showFormSubmittedSnackbar: false,
      graphicBeingMoved: undefined,
      showMoveFeatureSnackbar: false,
      moveFeatureDragEvt: undefined,
      canEdit: false,
      alreadySubmittedFinalOnline: false,
      highlightedRowIds: [],
      mapServices: [],
      showFeatureLayerItemSuccessSnacbkbar: false,
      showApplyEditResultDialog: false,
      updateResultErrorMessage: "",
    };
  },
  methods: {
    highlightRow(value) {
      const { mapServiceId: selectedMapServiceId } = value;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });

      if (selectedLayer?.utiliSyncLayerType === "U") {
        const { object_id: featureId } = value;
        this.highlightedRowIds = [featureId];
      } else if (selectedLayer?.utiliSyncLayerType === "F") {
        const field = selectedLayer?.fields?.find((f) => f.type === "oid");
        const objectIdFieldName = field?.name;
        const { OBJECTID, [objectIdFieldName]: objectId } = value;
        this.highlightedRowIds = [objectId ?? OBJECTID];
      }
    },
    async getActiveTasks() {
      const { objectId, selectedMapServiceId } = this;
      if (!objectId || !selectedMapServiceId || !navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/action_item_and_tasks`, {
        params: {
          map_service_id: selectedMapServiceId,
          object_id: objectId,
        },
      });
      this.tasks = results;
      const filteredTasks = this.tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled"
      );
      this.taskCount = filteredTasks.length;
    },
    async viewForm() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { feature_id: objectId, map_service_id: mapServiceId } = formResult;
      const { gisDataPointId } = formResult.feature_attributes;
      this.canEdit = false;
      this.alreadySubmittedFinalOnline = true;
      const dynamicFormProps = {
        formDefinition: formResult,
        existingFormResultIdMap: {
          formResultId,
        },
        selectedPdfFileUrl: formResult.pdfFileUrl,
        globalId: gisDataPointId,
        objectId,
        selectedMapServiceId: mapServiceId,
        canEdit: formResult?.form?.status !== "SUBMITTED_FINAL",
      };
      this.setDynamicFormProps(dynamicFormProps);
      this.setFormSubmitted({
        isFormSubmitted: false,
        formResultId: undefined,
      });
    },
    onResize() {
      this.bottomPaneHeight = this.$refs.bottomPane?.clientHeight ?? 0;
      this.progressBarHeight = this.$refs.progressBar?.$el?.clientHeight ?? 0;
    },
    async onRedrawerLayersFinished() {
      this.showBottomCard = false;
      await this.bulkDownloadGisDataPoints();
      await this.$nextTick();
      this.showBottomCard = true;
      if (this.showGisInfoPanel) {
        this.showGisInfoPanel = false;
        await this.$nextTick();
        this.showGisInfoPanel = true;
      }
      this.highlightSelectedSite();
    },
    async highlightSelectedSite() {
      const { map, view } = this;
      const { mapServiceId, OBJECTID } = this.selectedSite;
      const layer = map?.layers?._items?.find((l) => {
        return l.mapServiceId === mapServiceId;
      });
      const layerView = await view.whenLayerView(layer);
      const results = await layerView?.queryFeatures?.();
      const feature = results?.features.find((f) => {
        return getGisInfoObjectId(f) === OBJECTID;
      });
      if (feature) {
        view.goTo(feature);
        this.highlightItem(feature);
        this.gisInfos = [feature];
        this.selectedGisInfoIndex = 0;
        this.selectedSite = {};
      }
    },
    onGisFieldSaved(selectedMapServiceId) {
      this.setIsRedrawLayers({ selectedMapServiceId });
    },
    async onMoveFeature() {
      this.showMoveFeatureSnackbar = true;
      const [graphic] = this.gisInfos;
      this.graphicBeingMoved = graphic;
      const layer = graphic?.layer;
      this.moveFeatureDragEvt = this.view.on("drag", async (e) => {
        e.stopPropagation();
        if (e.action !== "end") {
          if (layer?.utiliSyncLayerType === "U") {
            graphic.geometry = this.view.toMap(e);
          }
        }
      });
    },
    async confirmMoveFeature() {
      const graphic = this.graphicBeingMoved;
      const layer = graphic?.layer;
      if (layer?.utiliSyncLayerType === "U") {
        const { latitude, longitude } = graphic.geometry;
        await axiosWithRegularAuth.put(
          `${APIURL}/gis_data_points/${graphic.attributes.gisDataPointId}`,
          {
            coordinates: [longitude, latitude],
          }
        );
        const layer = graphic?.layer;
        await layer?.applyEdits?.({
          updateFeatures: [graphic],
        });
        this.moveFeatureDragEvt.remove();
        const mapServiceId = graphic?.layer?.mapServiceId;
        this.setIsRedrawLayers({ selectedMapServiceId: mapServiceId });
      }
      this.showMoveFeatureSnackbar = false;
    },
    async cancelMoveFeature() {
      const graphic = this.graphicBeingMoved;
      const mapServiceId = graphic?.layer?.mapServiceId;
      this.showGisInfoPanel = false;
      this.setIsRedrawLayers({ selectedMapServiceId: mapServiceId });
      this.graphicBeingMoved = undefined;
      this.showMoveFeatureSnackbar = false;
    },
    onDeleteFeature(objectId) {
      this.objectIdOfFeatureToDelete = objectId;
      this.showDeleteFeatureDialog = true;
    },
    async confirmDeleteFeature() {
      this.showDeleteFeatureDialog = false;

      const graphic = this.gisInfos.find((g) => {
        const fields = g?.layer?.fields ?? [];
        const objectIdFieldName = fields?.find((f) => f.type === "oid")?.name;
        return (
          g?.attributes?.[objectIdFieldName] ===
            this.objectIdOfFeatureToDelete &&
          g?.layer?.mapServiceId === this.selectedMapServiceId
        );
      });
      const layer = graphic?.layer;
      if (layer?.utiliSyncLayerType === "U") {
        const { gisDataPointId } = graphic?.attributes ?? {};
        if (gisDataPointId) {
          await axiosWithRegularAuth.delete(
            `${APIURL}/gis_data_points/${gisDataPointId}`
          );
        }
      } else if (layer?.utiliSyncLayerType === "F") {
        try {
          await layer?.applyEdits?.({
            deleteFeatures: [graphic],
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.objectIdOfFeatureToDelete = undefined;
      this.showGisInfoPanel = false;
      this.gisInfos = [];
      const mapServiceId = graphic?.layer?.mapServiceId;
      this.setIsRedrawLayers({ selectedMapServiceId: mapServiceId });
    },
    async createGraphic(info, layer, { longitude, latitude }) {
      const { utilisyncRenderer, utiliSyncLayerType } = layer ?? {};
      const [Graphic] = await loadModules(["esri/Graphic"]);
      if (utiliSyncLayerType === "U") {
        const utilisyncPoint = {
          reference_field_value: info?.field_option_value,
        };
        const symbol = setGraphicSymbol({
          graphicValue: utilisyncPoint.reference_field_value,
          utilisyncRenderer,
        });
        const point = {
          type: "point",
          longitude,
          latitude,
        };
        const graphic = new Graphic({
          geometry: point,
          symbol,
        });
        return graphic;
      } else if (utiliSyncLayerType === "F") {
        const { symbol } = info;
        let type;
        if (["simple-marker", "picture-marker"].includes(symbol.type)) {
          type = "point";
        } else if (["simple-line"].includes(symbol.type)) {
          type = "polyline";
        } else if (["simple-fill", "picture-fill"].includes(symbol.type)) {
          type = "polygon";
        } else {
          this.showUnknownSymbolTypeDialog = true;
          return;
        }

        const point = {
          type,
          longitude,
          latitude,
        };
        const gisDataFieldName =
          this.mapServiceOfFeatureBeingAdded?.utilisyncRenderer
            ?.reference_field;
        const gisDataValue =
          this.mapServiceOfFeatureBeingAdded?.utilisyncRenderer?.renderer_symbols?.find(
            (s) =>
              s?.renderer_symbol_id ===
              this.rendererInfoBeingAdded?.renderer_symbol_id
          )?.field_option_value;

        let value = {};
        if (layer.renderer && ["unique-value"].includes(layer.renderer.type)) {
          const attrValue = layer.renderer.uniqueValueInfos?.find(
            (i) => i.symbol.id === info.symbol.id
          )?.value;
          value = {
            [layer.renderer.field]: attrValue,
          };
        } else if (["class-breaks"].includes(layer.renderer.type)) {
          const attrValue = layer.renderer.classBreakInfos?.find(
            (i) => i.symbol.id === info.symbol.id
          )?.value;
          value = {
            [layer.renderer.field]: attrValue,
          };
        } else if (["simple"].includes(layer.renderer.type)) {
          value = {};
        }

        const graphic = new Graphic({
          geometry: point,
          symbol,
          attributes: {
            gis_data_field_name: gisDataFieldName,
            gis_data_value: gisDataValue,
            ...(layer.renderer.field ? value : {}),
          },
        });
        return graphic;
      }
    },
    async getGeolocationPermission() {
      const geolocationStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      this.geolocationStatus = geolocationStatus;
    },
    async onAddSymbol(info, layer) {
      this.showFeaturePane = false;
      if (layer?.renderer && ["class-breaks"].includes(layer?.renderer?.type)) {
        this.showClassBreakValueDialog = true;
      }
      this.mapServiceOfFeatureBeingAdded = layer;
      this.selectedSymbolToAdd = info;
      await this.getGeolocationPermission();
      if (this.geolocationStatus?.state === "granted") {
        this.showGpsSnackbar = true;
        await this.addFeatureWithClick(info, layer);
      } else {
        this.showClickSnackbar = true;
        await this.addFeatureWithClick(info, layer);
      }
    },
    async addFeatureWithClick(info, layer) {
      const { mapServiceId } = layer;
      const { symbol } = info;
      let type = "point";
      if (["simple-marker", "picture-marker"].includes(symbol?.type)) {
        type = "point";
      } else if (["simple-line"].includes(symbol?.type)) {
        type = "polyline";
      } else if (["simple-fill", "picture-fill"].includes(symbol?.type)) {
        type = "polygon";
      }

      if (type === "point") {
        const action = this.draw.create("point");
        action.on("draw-complete", async (evt) => {
          const { coordinates } = evt;
          const [mapService] = await db.mapServices
            .filter((m) => m.map_service_id === mapServiceId)
            .toArray();
          const [webMercatorUtils] = await loadModules([
            "esri/geometry/support/webMercatorUtils",
          ]);
          const [longitude, latitude] =
            webMercatorUtils.xyToLngLat(...coordinates) ?? [];
          this.coordinatesOfFeatureBeingAdded = { longitude, latitude };
          if (mapService?.service_type === "U") {
            const layer = this.map?.layers?._items?.find((l) => {
              return l.mapServiceId === mapServiceId;
            });
            await this.addUtiliSyncLayerFeature(
              info,
              layer,
              latitude,
              longitude
            );
          } else if (mapService?.service_type === "F") {
            const layer = this.map?.layers?._items?.find((l) => {
              return l.mapServiceId === mapServiceId;
            });
            await this.addFeatureLayerFeature(info, layer, latitude, longitude);
          }
          await this.saveNewFeature();
        });
      } else {
        const action = this.draw.create(type);
        action.on(
          [
            "vertex-add",
            "vertex-remove",
            "cursor-update",
            "redo",
            "undo",
            "draw-complete",
          ],
          async (evt) => {
            await this.createPolygonGraphic(evt, mapServiceId, info);
          }
        );
      }
    },
    async createPolygonGraphic(evt, mapServiceId, info) {
      const { vertices, type: evtType } = evt;
      const [Graphic] = await loadModules(["esri/Graphic"]);
      const layer = this.map?.layers?._items?.find((l) => {
        return l.mapServiceId === mapServiceId;
      });
      const { view } = this;
      const { symbol } = info;
      const { spatialReference } = view;
      const { type, color, style, width } = symbol;
      let geometry = {};
      if (type === "simple-line") {
        geometry = {
          type: "polyline",
          paths: vertices,
          spatialReference,
        };
      } else {
        geometry = {
          type: "polygon",
          rings: vertices,
          spatialReference,
        };
      }

      const { r, g, b, a } = color;
      const graphic = new Graphic({
        geometry,
        symbol: {
          type,
          color: `rgba(${r},${g},${b}, ${a})`,
          style,
          outline: {
            color,
            width,
          },
        },
      });
      layer?.graphics?.add?.(graphic);
      if (evtType === "draw-complete") {
        const { addFeatureResults } = await layer?.applyEdits?.({
          addFeatures: [graphic],
        });
        this.featureLayerFeaturesBeingAdded = addFeatureResults;
        this.selectedSymbolToAdd = undefined;
      } else {
        this.rendererInfoBeingAdded = info;
        this.selectedSymbolToAdd = info;
        this.graphicBeingAdded = graphic;
      }
    },
    async addFeatureWithGeolocation(info, layer) {
      const { mapServiceId } = layer;
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === mapServiceId)
        .toArray();
      if (mapService?.service_type === "U") {
        const layer = this.map?.layers?._items?.find((l) => {
          return l.mapServiceId === mapServiceId;
        });
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          this.coordinatesOfFeatureBeingAdded = { longitude, latitude };
          await this.addUtiliSyncLayerFeature(info, layer, latitude, longitude);
          await this.saveNewFeature();
          this.showGpsSnackbar = false;
        });
      } else if (mapService?.service_type === "F") {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          this.coordinatesOfFeatureBeingAdded = { longitude, latitude };
          await this.addFeatureLayerFeature(info, layer, latitude, longitude);
          await this.saveNewFeature();
          this.showGpsSnackbar = false;
        });
      }
    },
    async addUtiliSyncLayerFeature(info, layer, latitude, longitude) {
      const graphic = await this.createGraphic(info, layer, {
        longitude,
        latitude,
      });
      this.rendererInfoBeingAdded = info;
      this.graphicBeingAdded = graphic;
      layer?.graphics?.add?.(graphic);
    },
    async addFeatureLayerFeature(info, layer, latitude, longitude) {
      const graphic = await this.createGraphic(info, layer, {
        longitude,
        latitude,
      });
      this.rendererInfoBeingAdded = info;
      this.graphicBeingAdded = graphic;
      try {
        const { addFeatureResults } = await layer?.applyEdits?.({
          addFeatures: [graphic],
        });
        this.featureLayerFeaturesBeingAdded = addFeatureResults;
        if (addFeatureResults?.[0]?.error) {
          this.showApplyEditResultDialog = true;
          if (addFeatureResults?.[0]?.error?.message) {
            this.updateResultErrorMessage =
              addFeatureResults?.[0]?.error?.message;
          } else if (addFeatureResults?.[0]?.error?.details?.[0]) {
            this.updateResultErrorMessage =
              addFeatureResults?.[0]?.error?.details?.[0];
          }
        } else if (addFeatureResults?.[0]) {
          this.showFeatureLayerItemSuccessSnacbkbar = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async cancelAddFeature() {
      this.showGpsSnackbar = false;
      this.showClickSnackbar = false;
      const { utiliSyncLayerType } = this.mapServiceOfFeatureBeingAdded ?? {};
      if (utiliSyncLayerType === "U") {
        if (this.gisDataPointBeingAdded) {
          await axiosWithRegularAuth.delete(
            `${APIURL}/gis_data_points/${this.gisDataPointBeingAdded.gisDataPointId}`
          );
        }
      } else if (utiliSyncLayerType === "F") {
        try {
          const [featureToDelete] = this.featureLayerFeaturesBeingAdded;
          await this.mapServiceOfFeatureBeingAdded?.applyEdits?.({
            deleteFeatures: [{ objectId: featureToDelete?.objectId }],
          });
        } catch (error) {
          console.log(error);
        }
      }
      const { selectedMapServiceId } = this;
      this.setIsRedrawLayers({ selectedMapServiceId });
      this.selectedSymbolToAdd = undefined;
      this.gisDataPointBeingAdded = undefined;
      this.graphicBeingAdded = undefined;
      this.mapServiceOfFeatureBeingAdded = undefined;
      this.featureLayerFeaturesBeingAdded = undefined;
    },
    async saveNewFeature() {
      this.showGpsSnackbar = false;
      this.showClickSnackbar = false;
      const { mapServiceId, utiliSyncLayerType } =
        this.mapServiceOfFeatureBeingAdded;
      if (utiliSyncLayerType === "U") {
        const gisDataFieldName =
          this.mapServiceOfFeatureBeingAdded?.utilisyncRenderer
            ?.reference_field;

        const gisDataValue =
          this.mapServiceOfFeatureBeingAdded?.utilisyncRenderer?.renderer_symbols?.find(
            (s) =>
              s?.renderer_symbol_id ===
              this.rendererInfoBeingAdded?.renderer_symbol_id
          )?.field_option_value;
        const { longitude, latitude } = this.coordinatesOfFeatureBeingAdded;
        const gisDataPoint = {
          map_service_id: mapServiceId,
          coordinates: [longitude, latitude],
          gis_data_field_name: gisDataFieldName ?? undefined,
          gis_data_value: gisDataValue ?? undefined,
        };
        const {
          data: { results },
        } = await axiosWithRegularAuth.post(
          `${APIURL}/gis_data_points`,
          gisDataPoint
        );
        this.gisDataPointBeingAdded = results;
        const { object_id: objectId } = results;
        const {
          data: { results: gisDataValues },
        } = await axiosWithRegularAuth.get(`${APIURL}/gis_data_values`, {
          params: {
            map_service_id: mapServiceId,
            feature_id: objectId,
          },
        });
        await db.gisDataValues.bulkPut(gisDataValues);
        this.setIsRedrawLayers({ selectedMapServiceId: mapServiceId });
      } else if (utiliSyncLayerType === "F") {
        this.setIsRedrawLayers({ selectedMapServiceId: mapServiceId });
      }
      this.selectedSymbolToAdd = undefined;
      this.gisDataPointBeingAdded = undefined;
      this.graphicBeingAdded = undefined;
      this.mapServiceOfFeatureBeingAdded = undefined;
      this.rendererInfoBeingAdded = undefined;
      this.coordinatesOfFeatureBeingAdded = { longitude: 0, latitude: 0 };
    },
    onOpenFeaturePane() {
      this.showFeaturePane = true;
    },
    onGisInfoPanelClose() {
      this.showGisInfoPanel = false;
      this.gisInfos = [];
      this.removeAllHighlight();
      this.highlightedRowIds = [];
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async getMaps() {
      this.maps = await db.maps.orderBy("name").toArray();
    },
    async onMobileTicketsLoaded(mobileTickets) {
      this.mobileTickets = mobileTickets;
      await this.$nextTick();
    },
    onCurrentItems(e) {
      this.currentPageItems = e;
      const { ticketStatus } = this.$route.query;
      if (
        this.totalResults > this.options.itemsPerPage &&
        ticketStatus === "closed"
      ) {
        this.showNewestClosedTicketSnackbar = true;
      }
    },
    async onPagination({ page, itemsPerPage }) {
      this.currentPage = page;
      this.currentItemsPerPage = itemsPerPage;
    },
    async onDraggedExtent({ extentGraphic }) {
      await sleep(2000);
      this.view.graphics.remove(extentGraphic);
      this.selectOnMap = false;
      this.showSearchDialog = true;
      this.mapAreaSelectedChanged = true;
    },
    minimizeTicketList() {
      if (this.split) {
        this.split.setSizes([100, 0]);
      }
      this.showLayerBottomSheet = false;
    },
    async onTicketEditFormSubmitted() {
      await this.$nextTick();
      this.selectedTickets = [];
      this.selectAllTickets = false;
      if (!navigator.onLine) {
        return;
      }
      const { selectedMapServiceId } = this;
      this.setIsRedrawLayers({ selectedMapServiceId });
    },
    async onTicketBottomSheetClose() {
      this.ticketBottomSheetFullScreen = false;
      this.showGisInfoPanel = false;
    },
    getTopSize() {
      if (!this.split) {
        return;
      }
      const [topSize] = this.split?.getSizes() ?? [50, 50];
      return topSize;
    },
    async onToggleTicketBottomSheet() {
      if (!this.split) {
        return;
      }
      this.showGisInfoPanel = false;
      if (this.split) {
        const [, bottomSize] = this.split?.getSizes() ?? [50, 50];

        if (
          Math.trunc(
            (bottomSize * this.$refs.container?.clientHeight) / 100
          ) === 7
        ) {
          this.split.setSizes([50, 50]);
          this.showLayerBottomSheet = true;
        } else {
          this.split.setSizes([100, 0]);
          this.showLayerBottomSheet = false;
        }
        this.onResize();
      }
    },
    async prev() {
      this.showGisInfoPanel = false;
      this.selectedGisInfoIndex = modulo(
        this.selectedGisInfoIndex - 1,
        this.gisInfos.length
      );
      await this.$nextTick();

      this.showGisInfoPanel = true;
      this.objectId = +this.featureId;
      await this.createSharedSiteIfNotExist();
    },
    async next() {
      this.showGisInfoPanel = false;
      this.selectedGisInfoIndex = modulo(
        this.selectedGisInfoIndex + 1,
        this.gisInfos.length
      );
      await this.$nextTick();

      this.showGisInfoPanel = true;
      this.objectId = +this.featureId;
      await this.createSharedSiteIfNotExist();
    },
    async handleFeatureTableRowClick(value) {
      this.highlightRow(value);
      this.selectedSite = value;
      const { mapServiceId, OBJECTID } = value;
      this.removeAllHighlight();
      this.showGisInfoPanel = false;
      await this.$nextTick();
      this.showGisInfoPanel = true;
      const layer = this.map?.layers?._items?.find((l) => {
        return l.mapServiceId === mapServiceId;
      });
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === mapServiceId)
        .toArray();
      const mapServiceUrl = mapService?.service_url;
      if (!navigator.onLine) {
        return;
      }
      const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
        params: {
          objectids: OBJECTID,
          outFields: "*",
          f: "json",
          token: localStorage.getItem("esri_token"),
        },
      });
      const { features } = data;
      const [selectedFeature] = features;
      const [webMercatorUtils] = await loadModules([
        "esri/geometry/support/webMercatorUtils",
      ]);
      const { x, y } = selectedFeature.geometry;
      const [lon, lat] = webMercatorUtils.xyToLngLat(x, y);
      const { view } = this;
      const xIsLon = x >= -180 && x <= 180;
      const yIsLat = y >= -90 && y <= 90;
      if (xIsLon && yIsLat) {
        await view.goTo({
          center: [x, y],
        });
      } else {
        await view.goTo({
          center: [lon, lat],
        });
      }
      await sleep(1000);
      const layerView = await view.whenLayerView(layer);
      const results = await layerView?.queryFeatures?.();
      const field = layer?.fields?.find((f) => f.type === "oid");
      const objectIdFieldName = field?.name;
      const feature = results?.features.find((f) => {
        return f.attributes[objectIdFieldName] === OBJECTID;
      });
      if (feature) {
        await view.goTo(feature);
        this.highlightItem(feature);
        this.gisInfos = [feature];
        this.selectedGisInfoIndex = 0;
        this.objectId = +this.featureId;
        await this.createSharedSiteIfNotExist();
      }
    },
    async handleRowClick(value) {
      this.highlightRow(value);
      const { map_service_id: mapServiceId, object_id: featureId } = value;
      this.selectedSite = { mapServiceId, OBJECTID: featureId };
      this.removeAllHighlight();
      this.showGisInfoPanel = false;
      await this.$nextTick();
      this.showGisInfoPanel = true;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === mapServiceId;
      });
      const { view } = this;
      if (selectedLayer?.utiliSyncLayerType === "U") {
        for (const item of selectedLayer.graphics._items) {
          if (item?.attributes?.OBJECTID === featureId) {
            view.goTo(item);
            this.highlightItem(item);
            this.gisInfos = [item];
            this.selectedGisInfoIndex = 0;
            this.objectId = +featureId;
            await this.createSharedSiteIfNotExist();
          }
        }
      }
    },
    async onMapCreated({ map, view }) {
      await this.$nextTick();
      this.map = map;
      this.view = view;
      const [Draw] = await loadModules(["esri/views/draw/Draw"]);
      this.draw = new Draw({
        view,
      });
    },
    async getData() {
      await this.$nextTick();
      await this.getMaps();
      this.setIsMapChanged(false);
      await this.setInitialSelectedMap();
    },
    async onPointerClickOutside() {
      if (this.showMoveFeatureSnackbar) {
        return;
      }
      this.removeAllHighlight();
      this.showGisInfoPanel = false;
      this.gisInfos = [];
    },
    removeAllHighlight() {
      for (const h of this.highlightSelect) {
        try {
          h?.remove();
        } catch (error) {
          console.log(error);
        }
      }
      this.highlightSelect = [];
    },
    async onPointerDownGisInfo(results) {
      this.removeAllHighlight();
      this.showGisInfoPanel = false;
      await this.$nextTick();
      this.showGisInfoPanel = true;
      this.gisInfos = results;
      const [gisInfo] = this.gisInfos;
      const { attributes, layer } = gisInfo;
      const { mapServiceId } = layer ?? {};
      this.selectedSite = { ...attributes, mapServiceId };
      this.objectId = +this.featureId;
      await this.createSharedSiteIfNotExist();
      this.selectedGisInfoIndex = 0;
      this.highlightItem(gisInfo);
    },
    async onMapSelected(selectedMapId) {
      this.showMapDialog = false;
      if (navigator.onLine) {
        await axiosWithRegularAuth.post(`${APIURL}/users/setmylastmap`, {
          map_id: selectedMapId,
        });
      }
      this.setMyLastMapSet(true);
      localStorage.setItem("last-map-opened-id", selectedMapId);
      this.updateAuthObjWithLastMapOpenedId(this.selectedMapId);
      this.setSelectedMapId(selectedMapId);
    },
    async setInitialSelectedMap() {
      const lastMapOpenedId = localStorage.getItem("last-map-opened-id");
      if (lastMapOpenedId) {
        if (navigator.onLine) {
          await axiosWithRegularAuth.post(`${APIURL}/users/setmylastmap`, {
            map_id: lastMapOpenedId,
          });
        }
        this.setMyLastMapSet(true);
        localStorage.setItem("last-map-opened-id", lastMapOpenedId);
        this.updateAuthObjWithLastMapOpenedId(lastMapOpenedId);
        return;
      }
      const mapResults = await db.maps.orderBy("name").toArray();
      if (navigator.onLine) {
        const [{ map_id: mapId } = {}] = mapResults;
        await axiosWithRegularAuth.post(`${APIURL}/users/setmylastmap`, {
          map_id: mapId,
        });
        this.setMyLastMapSet(true);
        localStorage.setItem("last-map-opened-id", mapId);
        this.updateAuthObjWithLastMapOpenedId(mapId);
        this.setSelectedMapId(mapId);
      }
    },
    createSplit() {
      try {
        this.split = Split(["#top", "#bottom"], {
          direction: "vertical",
          minSize: 0,
          gutterSize: 15,
          sizes: [100, 0],
          onDragEnd: () => {
            this.onResize();
          },
        });
      } finally {
        this.loaded = true;
      }
    },
    setMaskPosition(area) {
      if (area) {
        this.showMaskDiv = true;
        this.maskDivStyles = {
          ...this.maskDivStyles,
          left: `${area.x}px`,
          top: `${area.y}px`,
          width: `${area.width}px`,
          height: `${area.height}px`,
        };
      } else {
        this.showMaskDiv = false;
      }
    },
    async getFieldsOfSelectedMapService(selectedMapServiceId) {
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      const mapServiceUrl = mapService?.service_url;
      if (!mapServiceUrl || !navigator.onLine) {
        return;
      }
      const { data: queryResult } = await axiosWithNoAuth.get(
        `${mapServiceUrl}/query`,
        {
          params: {
            where: "1=1",
            outFields: "*",
            f: "json",
            token: localStorage.getItem("esri_token"),
          },
        }
      );
      const { fields } = queryResult;
      this.featureItemFields = fields;
    },
    setContentHeight() {
      if (!this.$refs.container) {
        return;
      }
      this.$refs.container.style.height = `${window.innerHeight - 56}px`;
    },
    async getLayers() {
      this.layers = await db.mapServices.toCollection().toArray();
    },
    async loadData() {
      try {
        if (localStorage.getItem("bulk-download-complete") !== "true") {
          this.showBottomCard = false;
          await this.bulkDownloadData();
        }
        localStorage.setItem("bulk-download-complete", true);
        await this.getData();
        this.showBottomCard = true;
        const lastFullDownloadCompleted = localStorage.getItem(
          "last-full-download-completed"
        );
        const fullDownload =
          !lastFullDownloadCompleted ||
          (lastFullDownloadCompleted &&
            moment().diff(moment(lastFullDownloadCompleted), "hours") >= 12);
        await this.downloadData(!fullDownload);
      } catch (error) {
        if (Object.values(Dexie.errnames).includes(error?.name)) {
          this.signOut();
        }
      } finally {
        await this.getData();
      }
    },
    async getFormResult(formResultId) {
      if (formResultId) {
        try {
          sessionStorage.setItem("formResultId", formResultId);
          const { data: selectedFormResult } = await axiosWithRegularAuth.get(
            `${APIURL}/form_results/${formResultId}`
          );
          const existingFormResultIdMap = {
            formResultId,
          };
          const selectedPdfFileUrl = selectedFormResult.pdfFileUrl;
          const { canEditAfterFinal } =
            selectedFormResult?.form?.formDescription ?? {};
          const alreadySubmittedFinalOnline =
            selectedFormResult?.form?.status === "SUBMITTED_FINAL";
          const { feature_id: objectId, map_service_id: mapServiceId } =
            selectedFormResult;
          const { gisDataPointId } = selectedFormResult.feature_attributes;
          const dynamicFormProps = {
            selectedFormResult,
            existingFormResultIdMap,
            alreadySubmittedFinalOnline,
            formDefinition: selectedFormResult,
            canEdit:
              (alreadySubmittedFinalOnline && canEditAfterFinal) ||
              !alreadySubmittedFinalOnline,
            selectedPdfFileUrl,
            objectId,
            globalId: gisDataPointId,
            selectedMapServiceId: mapServiceId,
          };
          this.setDynamicFormProps(dynamicFormProps);
        } catch (e) {
          console.log(e);
        }
      }
    },
    ...mapMutations([
      "setSelectedMapId",
      "setIsMapChanged",
      "setDownloadSummary",
      "setIsRedrawLayers",
      "setFormSubmitted",
      "setIsReloadMap",
      "setDynamicFormProps",
      "setMyLastMapSet",
    ]),
  },
  async beforeMount() {
    this.checkEsriTokenExpirationPeriodically();
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    if (this.$vuetify.breakpoint.xsOnly) {
      elHtml.style.overflowX = "hidden";
      document.body.style.overflowX = "hidden";
    }

    await this.$nextTick();
    this.createSplit();
    if (!navigator.onLine) {
      localStorage.removeItem("bulk-download-complete");
      localStorage.removeItem("last-full-download-completed");
    }
    await this.loadData();
    window.addEventListener("offline", this.onOnlineStatusChange);
    window.addEventListener("online", this.onOnlineStatusChange);
    await this.getLayers();
  },
  beforeDestroy() {
    window.removeEventListener("offline", this.onOnlineStatusChange);
    window.removeEventListener("online", this.onOnlineStatusChange);
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
    this.setMyLastMapSet(false);
  },
  async mounted() {
    this.$refs.container.style.height = `${window.innerHeight - 56}px`;
    window.addEventListener("resize", this.setContentHeight);
    window.addEventListener("resize", this.onResize);

    window.addEventListener("resize", async () => {
      if (!this.split) {
        return;
      }

      if (this.$vuetify.breakpoint.xsOnly) {
        const [elHtml] = document.getElementsByTagName("html");
        elHtml.style.overflowX = "hidden";
        document.body.style.overflowX = "hidden";
      }
    });
    window.addEventListener("resize", this.onResize);
    await this.getActiveTasks();

    const formResultId = sessionStorage.getItem("formResultId");
    if (formResultId) {
      await this.getFormResult(formResultId);
    }
  },
  watch: {
    dynamicFormProps: {
      deep: true,
      async handler(val) {
        this.showEditFormDialog = false;
        await this.$nextTick();
        if (
          val?.formDefinition?.form &&
          val?.globalId &&
          val?.objectId &&
          val?.selectedMapServiceId
        ) {
          this.showEditFormDialog = true;
        }
      },
    },
    formSubmitted: {
      deep: true,
      handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.showFormSubmittedSnackbar = true;
        }
      },
    },
    async selectedMapServiceId(val) {
      await this.getFieldsOfSelectedMapService(val);
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    mapIdSelected: {
      immediate: true,
      async handler(val) {
        await this.$nextTick();
        this.selectedMapId = val;
        this.showGisInfoPanel = false;
        this.showFeaturePane = false;
        this.totalResults = 0;
        this.pagination.current = 1;
      },
    },
    selectedGisInfoIndex: {
      immediate: true,
      async handler(val) {
        const gisInfo = this.gisInfos?.[val];
        if (!gisInfo) {
          return;
        }
        await this.removeAllHighlight();
        await this.getGisInfoData(gisInfo);
        this.view.goTo(gisInfo);
        await this.highlightItem(gisInfo);
      },
    },
    gisInfos: {
      deep: true,
      immediate: true,
      async handler(val) {
        const gisInfo = val?.[this.selectedGisInfoIndex];
        if (!gisInfo) {
          return;
        }
        await this.getGisInfoData(gisInfo);
      },
    },
    async showGisInfoPanel(val) {
      if (!val) {
        this.openGisInfoPanels = [1];
      }
    },
    "$route.query": {
      deep: true,
      handler() {
        this.showGisInfoPanel = false;
      },
    },
  },
};
</script>

<style scoped>
#top,
#bottom {
  overflow-y: auto;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-bottom: 0;
}

#ticket-log {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>

<style>
.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}

.drop-down {
  height: 100%;
}

.group-header {
  background-color: white;
}
</style>
