<template>
  <v-dialog v-model="showDeleteFeatureDialog" max-width="600" close persistent>
    <v-card id="deleteFeatureDialog">
      <v-card-text class="py-5">
        <h2 class="font-weight-regular">Delete Feature?</h2>
        <p class="pt-5">
          This is permanent and cannot be undone. All forms completed from this
          site will be permanently deleted.
        </p>
      </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end gap">
        <v-btn text @click="$emit('cancel-confirm-delete')"> Cancel </v-btn>
        <v-btn color="#3F51B5" dark @click="$emit('confirm-delete')">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteFeatureDialog",
  props: {
    showDeleteFeatureDialog: Boolean,
  },
};
</script>
