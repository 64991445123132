<template>
  <div id="viewDiv" v-basemap-resize>
    <v-snackbar v-model="trackSnackbar" v-if="!tracking" timeout="-1">
      Track location?
      <template v-slot:action="{ attrs }">
        <v-btn color="#00A9F4" text v-bind="attrs" @click="stopLocating()">
          Close
        </v-btn>
        <v-btn color="#00A9F4" v-bind="attrs" @click="startTracking()">
          Track
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="tracking" v-else timeout="-1">
      Stop Tracking Location?
      <template v-slot:action="{ attrs }">
        <v-btn color="#00A9F4" text v-bind="attrs" @click="stopTracking()">
          Stop
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import mapLayerMixin from "@/mixins/mapLayerMixin";
import { mapGetters, mapMutations } from "vuex";
import Layers from "@/components/mapView/Layers.vue";
import MeasureButton from "@/components/mapView/MeasureButton.vue";
import SelectButton from "@/components/mapView/esri-map-view/SelectButton.vue";
import FeatureButton from "@/components/mapView/FeatureButton.vue";
import Vue from "vue";
import Vuetify from "vuetify";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";

const LayersClass = Vue.extend(Layers);
const MeasureButtonClass = Vue.extend(MeasureButton);
const SelectButtonClass = Vue.extend(SelectButton);
const FeatureButtonClass = Vue.extend(FeatureButton);
const vuetify = new Vuetify();
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "EsriMapView",
  data() {
    return {
      view: undefined,
      measurementWidgetComponent: undefined,
      map: undefined,
      selectBtn: undefined,
      layerListExpand: undefined,
      bgExpand: undefined,
      measureButton: undefined,
      magifyBtn: undefined,
      featureBtn: undefined,
      trackSnackbar: false,
      trackWidget: undefined,
      locateWidget: undefined,
      tracking: false,
    };
  },
  props: {
    selectOnMap: Boolean,
  },
  computed: {
    ...mapGetters(["mapIdSelected", "isReloadMap", "isRedrawLayers"]),
  },
  mixins: [mapLayerMixin],
  beforeDestroy() {
    this.setCartographicScale();

    if (this.view) {
      this.view.destroy();
    }
  },
  methods: {
    async getCustomBasemaps() {
      const [Basemap, WMSLayer, TileLayer, VectorTileLayer] = await loadModules(
        [
          "esri/Basemap",
          "esri/layers/WMSLayer",
          "esri/layers/TileLayer",
          "esri/layers/VectorTileLayer",
        ]
      );

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/basemaps`);

      const customBasemaps = results.map((basemap) => {
        let baseLayer = null;
        if (basemap.type === "T") {
          baseLayer = new TileLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        } else if (basemap.type === "VT") {
          baseLayer = new VectorTileLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        } else if (basemap.type === "WMS") {
          baseLayer = new WMSLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        }

        const customBasemap = new Basemap({
          baseLayers: [baseLayer],
          thumbnailUrl:
            "https://utilisync-static.s3.amazonaws.com/customBasemap.jpg",
          title: basemap.name,
        });

        if (customBasemap) {
          return customBasemap;
        }
      });

      return customBasemaps;
    },
    stopLocating() {
      this.locateWidget.cancelLocate();
      this.trackSnackbar = false;
    },
    startTracking() {
      this.locateWidget.cancelLocate();
      this.trackSnackbar = false;
      this.tracking = true;
      this.trackWidget.start();
    },
    stopTracking() {
      this.trackWidget.stop();
      this.tracking = false;
    },
    moveMagnifyingGlass() {
      const [width, height] = this.view.size;
      const x = width / 2;
      const y = height / 2;
      this.view.magnifier.position = { x, y };
    },
    moveSelectButton() {
      const [width, height] = this.view.size;
      this.selectBtn.style.left = `${width / 2 - 45}px`;
      this.selectBtn.style.top = `${height / 2 + 60}px`;
    },
    minimizeTicketList() {
      this.$emit("minimize-ticket-list");
    },
    async getLayersInMap(mapId) {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const params = {
        map_id: mapId,
        user_group_id: userGroupId,
      };

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services/inmap`, {
        params,
      });
      return results;
    },
    async renderMap() {
      if (!localStorage.getItem("auth")) {
        return;
      }
      this.view?.destroy();
      this.map?.destroy();
      const [
        Map,
        MapView,
        reactiveUtils,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        Track,
        Locate,
        BasemapGallery,
        Expand,
        Extent,
        esriConfig,
        Search,
        Basemap,
        WMSLayer,
        TileLayer,
        VectorTileLayer,
      ] = await loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/core/reactiveUtils",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/widgets/Track",
        "esri/widgets/Locate",
        "esri/widgets/BasemapGallery",
        "esri/widgets/Expand",
        "esri/geometry/Extent",
        "esri/config",
        "esri/widgets/Search",
        "esri/Basemap",
        "esri/layers/WMSLayer",
        "esri/layers/TileLayer",
        "esri/layers/VectorTileLayer",
      ]);
      let defaultCenter = {};
      try {
        const centerString = localStorage.getItem("default-center");
        if (centerString) {
          defaultCenter = JSON.parse(centerString);
        } else {
          defaultCenter = {};
        }
      } catch (error) {
        defaultCenter = {};
      }

      const msResults = await db.mapServices.toCollection().toArray();
      const { mapIdSelected } = this;
      if (!mapIdSelected) {
        return;
      }
      const mapServices = msResults;
      const {
        data: { results: mapResult },
      } = await axiosWithRegularAuth.get(`${APIURL}/maps/${mapIdSelected}`);
      const {
        map_id: mapId,
        center_x_coord: x,
        center_y_coord: y,
        wkid,
        zoom_level: zoomLevel = 20,
      } = mapResult;
      const defaultZoomLevel = localStorage.getItem("default-zoom");
      const basemapInfo = JSON.parse(localStorage.getItem("basemap-info"));
      let selectedBasemap = null;
      if (basemapInfo && basemapInfo.custom_basemap) {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/basemaps/${basemapInfo.id}`
        );
        const basemap = results;
        let baseLayer = null;
        if (basemap.type === "T") {
          baseLayer = new TileLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        } else if (basemap.type === "VT") {
          baseLayer = new VectorTileLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        } else if (basemap.type === "WMS") {
          baseLayer = new WMSLayer({
            url: basemap.url,
            id: basemap.basemap_id,
          });
        }

        selectedBasemap = new Basemap({
          baseLayers: [baseLayer],
          thumbnailUrl:
            "https://utilisync-static.s3.amazonaws.com/customBasemap.jpg",
          title: basemap.name,
        });
      } else if (basemapInfo && !basemapInfo.custom_basemap) {
        selectedBasemap = { portalItem: { id: basemapInfo.id } };
      }

      const map = new Map({
        basemap: selectedBasemap !== null ? selectedBasemap : "streets-vector",
      });
      this.map = map;
      const { latitude = 0, longitude = 0 } = defaultCenter;
      this.view = new MapView({
        container: this.$el,
        map,
        center: [longitude, latitude],
        zoom: +defaultZoomLevel || zoomLevel,
        popup: {
          autoOpenEnabled: false, // We do not want the default popup to display
        },
      });
      this.view.ui.remove("zoom");
      this.view.center = new Point({
        x,
        y,
        spatialReference: {
          wkid,
        },
      });
      const extent = JSON.parse(localStorage.getItem("view-extent"));
      if (extent) {
        const mapExtent = new Extent(extent);
        this.view.extent = mapExtent;
      }
      const finalWkid = typeof wkid === "number" ? wkid : 102100;
      if (Object.keys(defaultCenter).length > 0) {
        const { x, y } = defaultCenter;
        const mapCenter = new Point(
          x,
          y,
          new SpatialReference({ wkid: finalWkid })
        );
        this.view.center = mapCenter;
        this.view.zoom = +(localStorage.getItem("default-zoom") ?? 20);
      } else {
        const {
          center_x_coord: centerXCoord,
          center_y_coord: centerYCoord,
          wkid,
          zoom_level: zoomLevel,
        } = mapResult;
        const finalWkid = typeof wkid === "number" ? wkid : 102100;
        const mapCenter = new Point(
          centerXCoord,
          centerYCoord,
          new SpatialReference({ wkid: finalWkid })
        );
        this.view.center = mapCenter;
        this.view.zoom = +defaultZoomLevel || zoomLevel;
      }

      const layersArr = await this.getLayersInMap(mapId);
      const defaultLayersVisibility = layersArr.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });

      const { map_services: layers = [] } = mapResult;
      let layersVisibilitySettings = [];
      try {
        const layersVisibilitySettingsStr = localStorage.getItem(
          "layer-visibility-settings"
        );
        layersVisibilitySettings = JSON.parse(layersVisibilitySettingsStr);
        if (!Array.isArray(layersVisibilitySettings)) {
          layersVisibilitySettings = [];
        }
      } catch (error) {
        layersVisibilitySettings = [];
      }
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        esriConfig,
        config: {
          mapConfig: {
            map_services: mapServices,
          },
          loadedLayers: [],
          layers,
        },
        defaultLayersVisibility,
        layersVisibilitySettings,
        mapIdSelected,
      });

      map.watch("basemap", (evt) => {
        if (evt.portalItem) {
          localStorage.setItem(
            "basemap-info",
            JSON.stringify({
              custom_basemap: false,
              id: evt.portalItem.id,
            })
          );
        } else {
          const bml = JSON.parse(JSON.stringify(evt)).baseMapLayers;
          localStorage.setItem(
            "basemap-info",
            JSON.stringify({
              custom_basemap: true,
              id: bml[0].id,
            })
          );
        }
      });

      for (const layer of map.allLayers) {
        reactiveUtils.watch(
          () => layer?.visible,
          () => {
            let layersVisibilitySettings = [];
            const { mapIdSelected } = this;
            try {
              const layersVisibilitySettingsStr = localStorage.getItem(
                "layer-visibility-settings"
              );
              layersVisibilitySettings = JSON.parse(
                layersVisibilitySettingsStr
              );
              if (!Array.isArray(layersVisibilitySettings)) {
                layersVisibilitySettings = [];
              }
            } catch (error) {
              layersVisibilitySettings = [];
            } finally {
              const layersVisibilitySetting = layersVisibilitySettings.find(
                (l) => l.mapId === mapIdSelected
              );
              if (layersVisibilitySetting) {
                const layersVisibilitySettingIndex =
                  layersVisibilitySettings.findIndex(
                    (l) => l.mapId === mapIdSelected
                  );
                const newLayersVisibilitySettings = [
                  ...layersVisibilitySettings,
                ];
                const {
                  layerVisibilitySettings: existingLayerVisibilitySettings,
                } = newLayersVisibilitySettings[layersVisibilitySettingIndex];
                newLayersVisibilitySettings[layersVisibilitySettingIndex] = {
                  ...layersVisibilitySetting,
                  layerVisibilitySettings: {
                    ...existingLayerVisibilitySettings,
                    [layer.mapServiceId]: layer?.visible,
                  },
                };
                const newLayersVisibilitySettingsStr = JSON.stringify(
                  newLayersVisibilitySettings
                );
                localStorage.setItem(
                  "layer-visibility-settings",
                  newLayersVisibilitySettingsStr
                );
              } else {
                const newLayersVisibilitySetting = {
                  mapId: mapIdSelected,
                  layerVisibilitySettings: {
                    [layer.mapServiceId]: layer?.visible,
                  },
                };
                layersVisibilitySettings.push(newLayersVisibilitySetting);
                const layersVisibilitySettingsStr = JSON.stringify(
                  layersVisibilitySettings
                );
                localStorage.setItem(
                  "layer-visibility-settings",
                  layersVisibilitySettingsStr
                );
              }
            }
          }
        );
      }

      const { view, minimizeTicketList } = this;
      this.trackWidget = new Track({
        view,
        goToLocationEnabled: true,
        geolocationOptions: {
          maximumAge: 0,
          timeout: 15000,
          enableHighAccuracy: true,
        },
        useHeadingEnabled: false,
        id: "track",
      });
      const locateBtn = document.createElement("div");
      locateBtn.id = "locate-btn";
      locateBtn.className =
        "esri-icon-locate esri-widget--button esri-widget esri-interactive";

      this.locateWidget = new Locate({
        view,
        goToLocationEnabled: true,
        geolocationOptions: {
          maximumAge: 0,
          timeout: 15000,
          enableHighAccuracy: true,
        },
        useHeadingEnabled: false,
        id: "locate",
      });
      locateBtn.addEventListener("click", async () => {
        this.locateWidget.cancelLocate();
        this.trackWidget.stop();
        this.tracking = false;
        this.locateWidget.locate();
        this.trackSnackbar = true;
      });

      const customBasemaps = await this.getCustomBasemaps();

      const basemapGallery = new BasemapGallery({
        view,
        source: {
          query: {},
          updateBasemapsCallback: (items) => {
            items.unshift(...customBasemaps);
            return items;
          },
        },
      });
      const bgExpand = new Expand({
        view,
        content: basemapGallery,
        mode: "floating",
        id: "bg-expand",
      });
      const homeBtn = document.createElement("div");
      homeBtn.id = "home-btn";
      homeBtn.className =
        "esri-icon-home esri-widget--button esri-widget esri-interactive";
      homeBtn.addEventListener("click", async () => {
        const {
          data: { results: newMapData },
        } = await axiosWithRegularAuth.get(`${APIURL}/maps/${mapIdSelected}`);
        const {
          center_x_coord: centerXCoord,
          center_y_coord: centerYCoord,
          wkid,
          zoom_level: zoomLevel,
        } = newMapData;
        const finalWkid = typeof wkid === "number" ? wkid : 102100;
        const mapCenter = new Point(
          centerXCoord,
          centerYCoord,
          new SpatialReference({ wkid: finalWkid })
        );
        this.view.center = mapCenter;
        this.view.zoom = zoomLevel;
      });
      const searchBox = new Search({
        view,
      });
      const searchWidget = new Expand({
        expandIconClass: "esri-icon-search",
        view,
        content: searchBox,
        mode: "floating",
        id: "searchWidget",
      });

      this.selectBtn = new SelectButtonClass({ vuetify }).$mount().$el;
      this.selectBtn.id = "selectBtn";
      this.selectBtn.addEventListener("click", async () => {
        const [width, height] = this.view.size;
        const x = width / 2;
        const y = height / 2;
        const resp = await view.popup.fetchFeatures({ x, y });
        const results = await resp.allGraphicsPromise;
        if (Array.isArray(results)) {
          if (results.length === 0) {
            this.$emit("pointer-clicked-outside");
            return;
          }
          const popupResults = results.filter((r) => {
            const { layer, geometry } = r;
            return (
              geometry &&
              (layer?.fields ||
                layer?.mapServiceId ||
                layer?.utiliSyncLayerType === "U")
            );
          });
          this.$emit("pointer-down", popupResults);
        }
      });
      this.moveSelectButton();
      this.view.magnifier.visible = false;
      const magnifyBtn = document.createElement("div");
      magnifyBtn.id = "magnify-button";
      magnifyBtn.className =
        "esri-icon-zoom-in-magnifying-glass esri-widget--button esri-widget esri-interactive";
      magnifyBtn.addEventListener("click", () => {
        this.view.magnifier.visible = !this.view.magnifier.visible;
        if (this.view.magnifier.visible) {
          this.view.ui.add(this.selectBtn);
        } else {
          this.view.ui.remove(this.selectBtn);
        }

        this.view.on("resize", () => {
          this.moveMagnifyingGlass();
          this.moveSelectButton();
        });
        this.moveMagnifyingGlass();
        this.moveSelectButton();
      });

      const measureButtonComponent = new MeasureButtonClass({
        propsData: { view, minimizeTicketList },
        vuetify,
      });
      measureButtonComponent.$mount();
      this.view.on("click", async (event) => {
        // Use popup to select features on the map. Only features with popupTemplate enabled will be selected
        const resp = await view.popup.fetchFeatures(event);
        const results = await resp.allGraphicsPromise;
        if (Array.isArray(results)) {
          if (results.length === 0) {
            this.$emit("pointer-clicked-outside");
            return;
          }
          const popupResults = results.filter((r) => {
            const { layer, attributes, geometry } = r;
            return (
              geometry &&
              (layer?.mapServiceId ||
                (typeof attributes === "object" && attributes !== null))
            );
          });
          this.$emit("pointer-down", popupResults);
        }
      });

      this.featureBtn = new FeatureButtonClass({ vuetify }).$mount().$el;
      this.featureBtn.id = "feature-button";
      this.featureBtn.className =
        "esri-widget--button esri-widget esri-interactive";
      this.featureBtn.addEventListener("click", () => {
        this.$emit("open-feature-pane");
      });

      let extentGraphic = null;
      let origin = null;
      const fillSymbol = {
        type: "simple-fill",
        color: [227, 139, 79, 0.8],
        outline: {
          color: [255, 255, 255],
          width: 1,
        },
      };

      this.view.on("drag", (e) => {
        if (!this.selectOnMap) {
          return;
        }
        e.stopPropagation();
        if (e.action === "start") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          origin = view.toMap(e);
        } else if (e.action === "update") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          const p = view.toMap(e);
          const coords = {
            xmin: Math.min(p.x, origin.x),
            xmax: Math.max(p.x, origin.x),
            ymin: Math.min(p.y, origin.y),
            ymax: Math.max(p.y, origin.y),
          };
          extentGraphic = new Graphic({
            geometry: new Extent({
              ...coords,
              spatialReference: { wkid: 102100 },
            }),
            symbol: fillSymbol,
          });
          const { xmin, xmax, ymin, ymax } = coords;
          const [lonMin, latMin] = webMercatorUtils.xyToLngLat(xmin, ymin);
          const [lonMax, latMax] = webMercatorUtils.xyToLngLat(xmax, ymax);
          view.graphics.add(extentGraphic);
          this.$router.push({
            path: "/map",
            query: {
              ...this.$route.query,
              lonMin,
              latMin,
              lonMax,
              latMax,
              timestamp: +new Date(),
            },
          });
        } else if (e.action === "end") {
          if (extentGraphic) {
            view.graphics.remove(extentGraphic);
          }
          const p = view.toMap(e);
          const coords = {
            xmin: Math.min(p.x, origin.x),
            xmax: Math.max(p.x, origin.x),
            ymin: Math.min(p.y, origin.y),
            ymax: Math.max(p.y, origin.y),
          };
          extentGraphic = new Graphic({
            geometry: new Extent({
              ...coords,
              spatialReference: { wkid: 102100 },
            }),
            symbol: fillSymbol,
          });
          const { xmin, xmax, ymin, ymax } = coords;
          const [lonMin, latMin] = webMercatorUtils.xyToLngLat(xmin, ymin);
          const [lonMax, latMax] = webMercatorUtils.xyToLngLat(xmax, ymax);
          view.graphics.add(extentGraphic);
          this.$router.push({
            path: "/map",
            query: {
              ...this.$route.query,
              lonMin,
              latMin,
              lonMax,
              latMax,
              timestamp: +new Date(),
            },
          });
          this.$emit("dragged-extent", { coords, extentGraphic });
        }
      });
      await Promise.all(
        map.layers.items.map((lv) => {
          return Promise.race([
            reactiveUtils.whenOnce(() => lv?.loadStatus === "loaded"),
            reactiveUtils.whenOnce(() => lv?.loadStatus === "failed"),
          ]);
        })
      );

      if (!this.view.ui?.find("search")) {
        this.view.ui?.add(searchWidget, "top-left");
      }
      if (!this.view.ui?.find("zoom")) {
        this.view.ui?.add("zoom", "top-left");
      }
      if (!this.view.ui?.find("home-btn")) {
        this.view.ui?.add(homeBtn, "top-left");
      }
      if (!this.view.ui?.find("locate-btn")) {
        this.view.ui?.add(locateBtn, "top-left");
      }
      const layersComponent = new LayersClass({
        propsData: { map, mapId, allLayers: layersArr.flat() },
        vuetify,
      });
      layersComponent.$mount();
      const layerListExpand = new Expand({
        expandIconClass: "esri-icon-layers",
        view,
        content: layersComponent.$el,
        mode: "floating",
        id: "layers-expand",
      });
      if (this.view.ui?.find("layers-expand")) {
        this.view.ui?.remove("layers-expand");
      }
      this.view.ui?.add(layerListExpand, "top-right");
      if (this.view.ui?.find("bg-expand")) {
        this.view.ui?.remove("bg-expand");
      }
      this.view.ui?.add(bgExpand, "top-right");
      if (this.view.ui?.find("measure-button")) {
        this.view.ui?.remove("measure-button");
      }
      this.view.ui?.add(measureButtonComponent.$el, "top-right");

      if (this.view.ui?.find("magnify-button")) {
        this.view.ui?.remove("magnify-button");
      }
      this.view.ui?.add(magnifyBtn, "top-right");

      if (this.view.ui?.find("feature-button")) {
        this.view.ui?.remove("feature-button");
      }
      this.view.ui?.add(this.featureBtn, "top-right");
      this.$emit("map-created", { map, view });
    },
    async redrawLayers(selectedMapServiceId) {
      const layer = this.map?.layers?._items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });
      this.map.layers.remove(layer);
      const [
        reactiveUtils,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        MapImageLayer,
        esriConfig,
        BasemapGallery,
        Expand,
      ] = await loadModules([
        "esri/core/reactiveUtils",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/renderers/SimpleRenderer",
        "esri/layers/FeatureLayer",
        "esri/symbols/SimpleLineSymbol",
        "esri/Color",
        "esri/symbols/SimpleFillSymbol",
        "esri/rest/support/ImageParameters",
        "esri/renderers/UniqueValueRenderer",
        "esri/renderers/ClassBreaksRenderer",
        "esri/geometry/Point",
        "esri/geometry/SpatialReference",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/PopupTemplate",
        "esri/layers/MapImageLayer",
        "esri/config",
        "esri/widgets/BasemapGallery",
        "esri/widgets/Expand",
      ]);
      const {
        data: { results: mapResult },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/maps/${this.mapIdSelected}`
      );
      const { map_id: mapId } = mapResult;
      const layersArr = await this.getLayersInMap(mapId);
      const defaultLayersVisibility = layersArr.map((l) => {
        const { map_service_id: mapServiceId, is_visible: isVisible } = l;
        return {
          mapServiceId,
          isVisible,
        };
      });
      const {
        data: { results: msResults },
      } = await axiosWithRegularAuth.get(`${APIURL}/map_services`);
      const mapServices = msResults;
      const { map, mapIdSelected } = this;
      const { map_services: allLayers = [] } = mapResult;
      const layers = [
        allLayers.find((m) => m.map_service_id === selectedMapServiceId),
      ];

      let layersVisibilitySettings = [];
      try {
        const layersVisibilitySettingsStr = localStorage.getItem(
          "layer-visibility-settings"
        );
        layersVisibilitySettings = JSON.parse(layersVisibilitySettingsStr);
        if (!Array.isArray(layersVisibilitySettings)) {
          layersVisibilitySettings = [];
        }
      } catch (error) {
        layersVisibilitySettings = [];
      }
      this.addLayers({
        map,
        layers,
        GraphicsLayer,
        PictureMarkerSymbol,
        SimpleRenderer,
        FeatureLayer,
        MapImageLayer,
        SimpleLineSymbol,
        Color,
        SimpleFillSymbol,
        ImageParameters,
        UniqueValueRenderer,
        ClassBreaksRenderer,
        Point,
        SpatialReference,
        Graphic,
        webMercatorUtils,
        PopupTemplate,
        esriConfig,
        config: {
          mapConfig: {
            map_services: mapServices,
          },
          loadedLayers: [],
          layers,
        },
        defaultLayersVisibility,
        layersVisibilitySettings,
        mapIdSelected,
      });
      await Promise.all(
        map.layers.items.map((lv) => {
          return Promise.race([
            reactiveUtils.whenOnce(() => lv?.loadStatus === "loaded"),
            reactiveUtils.whenOnce(() => lv?.loadStatus === "failed"),
          ]);
        })
      );
      const { view, minimizeTicketList } = this;
      const layersComponent = new LayersClass({
        propsData: { map, mapId, allLayers: layersArr.flat() },
        vuetify,
      });
      layersComponent.$mount();
      const layerListExpand = new Expand({
        expandIconClass: "esri-icon-layers",
        view,
        content: layersComponent.$el,
        mode: "floating",
        id: "layers-expand",
      });
      const basemapGallery = new BasemapGallery({
        view,
      });
      const bgExpand = new Expand({
        view,
        content: basemapGallery,
        mode: "floating",
        id: "bg-expand",
      });
      const measureButtonComponent = new MeasureButtonClass({
        propsData: { view, minimizeTicketList },
        vuetify,
        id: "measure-button",
      });
      const magnifyBtn = document.createElement("div");
      magnifyBtn.id = "magnify-button";
      magnifyBtn.className =
        "esri-icon-zoom-in-magnifying-glass esri-widget--button esri-widget esri-interactive";
      magnifyBtn.addEventListener("click", () => {
        this.view.magnifier.visible = !this.view.magnifier.visible;
        if (this.view.magnifier.visible) {
          this.view.ui.add(this.selectBtn);
        } else {
          this.view.ui.remove(this.selectBtn);
        }

        this.view.on("resize", () => {
          this.moveMagnifyingGlass();
          this.moveSelectButton();
        });
        this.moveMagnifyingGlass();
        this.moveSelectButton();
      });
      if (this.view.ui?.find("layers-expand")) {
        this.view.ui?.remove("layers-expand");
      }
      this.view.ui?.add(layerListExpand, "top-right");
      if (this.view.ui?.find("bg-expand")) {
        this.view.ui?.remove("bg-expand");
      }
      this.view.ui?.add(bgExpand, "top-right");
      if (this.view.ui?.find("measure-button")) {
        this.view.ui?.remove("measure-button");
      }
      this.view.ui?.add(measureButtonComponent.$el, "top-right");

      if (this.view.ui?.find("magnify-button")) {
        this.view.ui?.remove("magnify-button");
      }
      this.view.ui?.add(magnifyBtn, "top-right");

      if (this.view.ui?.find("feature-button")) {
        this.view.ui?.remove("feature-button");
      }
      this.view.ui?.add(this.featureBtn, "top-right");
    },
    setCartographicScale() {
      if (!this.view) {
        return;
      }
      localStorage.setItem("default-zoom", this.view.zoom);
      const {
        cache,
        extent,
        hasM,
        hasZ,
        latitude,
        longitude,
        m,
        spatialReference,
        type,
        x,
        y,
        z,
      } = this.view.center;
      const center = {
        cache,
        extent,
        hasM,
        hasZ,
        latitude,
        longitude,
        m,
        spatialReference,
        type,
        x,
        y,
        z,
      };
      localStorage.setItem("default-center", JSON.stringify(center));
      localStorage.setItem("view-extent", JSON.stringify(this.view.extent));
    },
    ...mapMutations(["setIsReloadMap", "setIsMapChanged", "setIsRedrawLayers"]),
  },
  directives: {
    basemapResize: {
      inserted(el) {
        const mutationObserver = new MutationObserver(() => {
          const resizeObserver = new ResizeObserver(() => {
            const basemapGallery = document.querySelector(
              ".esri-basemap-gallery"
            );
            if (!basemapGallery) {
              return;
            }
            setTimeout(() => {
              basemapGallery.style.maxHeight = "unset";
              basemapGallery.style.height = `${Math.min(
                basemapGallery.scrollHeight,
                el.clientHeight - 60
              )}px`;
            });
          });
          resizeObserver.observe(el);
        });
        mutationObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      },
    },
  },
  watch: {
    mapIdSelected: {
      immediate: true,
      async handler(val) {
        if (!val || !navigator.onLine) {
          return;
        }
        this.setCartographicScale();
        await this.renderMap(val);
      },
    },
    async isReloadMap(val) {
      if (val) {
        await this.renderMap(this.mapIdSelected);
        this.setIsReloadMap(false);
        this.setIsMapChanged(false);
      }
    },
    async isRedrawLayers({ selectedMapServiceId }) {
      if (selectedMapServiceId) {
        await this.redrawLayers(selectedMapServiceId);
        this.setIsRedrawLayers(false);
        const { map, view } = this;
        this.$emit("redraw-layers-finished", { map, view });
      }
    },
  },
};
</script>

<style scoped>
#viewDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>

<style>
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

#viewDiv {
  outline: none;
}
</style>
