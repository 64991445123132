<template>
  <v-icon>
    {{ mdiMapMarkerPlus }}
  </v-icon>
</template>

<script>
import { mdiMapMarkerPlus } from "@mdi/js";

export default {
  name: "FeatureButton",
  data() {
    return {
      mdiMapMarkerPlus,
    };
  },
};
</script>
