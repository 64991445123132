<template>
  <div>
    <v-navigation-drawer
      app
      v-model="showTasksTab"
      right
      :permanent="showTasksTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Tasks</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTasksTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <TasksTab
          class="overflow-y-auto"
          v-if="showTasksTab"
          :globalId="globalId"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="objectId"
          @update-task-count="$emit('update-task-count', $event)"
        />
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showTicketLogTab"
      right
      :permanent="showTicketLogTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Log</div>
                <v-btn
                  icon
                  text
                  @click="
                    showTicketLogTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <Log
          class="overflow-y-auto"
          :globalId="globalId"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="objectId"
          v-if="showTicketLogTab"
        />
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="showDocsTab"
      right
      :permanent="showDocsTab"
      style="z-index: 101"
      hide-overlay
      width="375px"
      stateless
    >
      <div class="rounded-0 d-flex flex-column" style="height: 100vh">
        <v-list color="#3F51B5" class="my-0 py-1" width="100%">
          <v-list-item>
            <v-list-item-content class="py-0 my-0" two-line>
              <div class="d-flex justify-space-between align-center">
                <div class="white--text" style="font-size: 20px">Documents</div>
                <v-btn
                  icon
                  text
                  @click="
                    showDocsTab = false;
                    gisInfoTab = undefined;
                  "
                  style="font-size: 12px"
                >
                  <v-icon color="white">
                    {{ mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <DocsTab
          class="overflow-y-auto"
          v-if="showDocsTab"
          :globalId="globalId"
          :selectedMapServiceId="selectedMapServiceId"
          :objectId="objectId"
        />
      </div>
    </v-navigation-drawer>

    <GisDataPointFilterDialog
      v-if="showGisDataPointFilterDialog"
      :showGisDataPointFilterDialog="showGisDataPointFilterDialog"
      :gisDataPoints="gisDataPoints"
      :savedFilterChoices="filterChoices"
      :selectedMapServiceId="selectedMapServiceId"
      :featureItemFields="featureItemFields"
      @gis-data-point-filter-close="showGisDataPointFilterDialog = false"
      @update-filter="onUpdateFilter"
    />

    <section class="d-flex justify-space-between flex-wrap" ref="filterDisplay">
      <div class="d-flex align-center px-5">
        <TableViewDropdown
          v-if="onMapPage"
          class="pt-4"
          :currentSelectedTable="this.newSelectedTable"
          @selected-table="$emit('selected-table', $event)"
        />
        <v-autocomplete
          :items="layerChoices"
          v-model="selectedMapServiceId"
          item-text="label"
          item-value="value"
          @change="onLayerIdChange"
          label="Layer"
          :disabled="disableLayerSelect"
          menu-props="auto"
          class="autocomplete"
        >
        </v-autocomplete>

        <v-btn
          text
          class="px-1 ml-3"
          color="#3F51B5"
          @click="showGisDataPointFilterDialog = true"
          :disabled="!selectedMapServiceId"
        >
          <v-icon> {{ mdiFilter }}</v-icon>
          Filter
        </v-btn>
        <FilterChoicesDisplay
          class="flex-grow-1"
          :filterChoices="filterChoices"
          @remove-filter="removeFilter"
        />
      </div>

      <div class="align-center px-5">
        <v-btn
          text
          color="#3F51B5"
          @click="reload"
          :disabled="!isOnline || isDownloading"
          class="px-1 mx-1"
        >
          <v-progress-circular
            color="#3F51B5"
            v-if="isDownloading"
            :size="15"
            indeterminate
            class="mr-1"
          ></v-progress-circular>
          <v-icon color="#3F51B5" class="mr-1" v-else>
            {{ mdiRefresh }}
          </v-icon>
          Reload
        </v-btn>
        <div class="text-caption">
          Updated: {{ lastUpdated | formatVariableDate("hh:mm A") }}
        </div>
      </div>
    </section>

    <div v-if="selectedLayerType === 'U'">
      <div class="d-flex justify-center" v-if="showLoader">
        <v-progress-circular indeterminate color="#3F51B5">
        </v-progress-circular>
      </div>
      <v-data-table
        v-else
        :headers="filteredHeaders"
        :items="filteredMappedGisDataPoints"
        :height="tableHeight"
        item-key="objectId"
        class="pa-0 ma-0 cursor-pointer"
        @click:row="onRowClick"
        hide-default-footer
        :footer-props="{
          'items-per-page-options': [100],
        }"
        :items-per-page="100"
        calculate-widths
        fixed-header
        :item-class="getRowClass"
      >
        <template v-slot:[`header.menu`]>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text color="#3F51B5">
                <v-icon dark>
                  {{ mdiPlusCircle }}
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="h of nonEmptyHeaders"
                  :key="h.value"
                  @click.stop
                  v-model="headersEnabled[h.value]"
                  :label="h.text"
                  class="py-0 my-0"
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>

        <template v-for="h of filteredHeaders" #[`item.${h.value}`]="{ item }">
          <div
            class="d-flex align-center"
            :key="h.value"
            v-if="isRichTextField(item[h.value])"
            v-html="item[h.value]"
          ></div>
          <div v-else :key="h.value" class="d-flex align-center">
            {{ item[h.value] }}
          </div>
        </template>

        <template v-slot:footer="{ props, on }">
          <div class="d-flex justify-end" ref="bottomBar">
            <div class="d-flex">
              <v-data-footer
                :items-per-page-options="[100]"
                :options="props.options"
                :pagination="props.pagination"
                v-on="on"
                style="border: none"
              >
              </v-data-footer>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <div v-else-if="selectedLayerType === 'F'">
      <div class="d-flex justify-center" v-if="showLoader">
        <v-progress-circular indeterminate color="#3F51B5">
        </v-progress-circular>
      </div>
      <v-data-table
        v-else
        :headers="filteredFeatureTableHeaders"
        :items="filteredSelectedFeatureLayerFeatures"
        item-key="OBJECTID"
        class="pa-0 ma-0"
        @click:row="onFeatureRowClick"
        hide-default-footer
        :footer-props="{
          'items-per-page-options': [100],
        }"
        :items-per-page="100"
        calculate-widths
        fixed-header
        :height="tableHeight"
        :item-class="getRowClass"
      >
        <template v-slot:[`header.menu`]>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text color="#3F51B5">
                <v-icon dark>
                  {{ mdiPlusCircle }}
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="h of nonEmptyFeatureLayerTableHeaders"
                  :key="h.value"
                  @click.stop
                  v-model="featureLayerTableHeadersEnabled[h.value]"
                  :label="h.text"
                  class="py-0 my-0"
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>

        <template
          v-for="h of filteredFeatureTableHeaders"
          #[`item.${h.value}`]="{ item }"
        >
          <div
            :key="h.value"
            v-if="isRichTextField(item[h.value])"
            v-html="item[h.value]"
          ></div>
          <div
            :key="h.value"
            v-else-if="
              getEsriFieldType(h.value) === 'esriFieldTypeDate' && item[h.value]
            "
          >
            {{ new Date(item[h.value]) | formatDate }}
          </div>
          <div v-else :key="h.value">{{ item[h.value] }}</div>
        </template>

        <template v-slot:footer="{ props, on }">
          <div class="d-flex justify-end" ref="bottomBar">
            <div class="d-flex">
              <v-data-footer
                :items-per-page-options="[100]"
                :options="props.options"
                :pagination="props.pagination"
                v-on="on"
                style="border: none"
              >
              </v-data-footer>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { mdiClose, mdiFilter, mdiPlusCircle, mdiRefresh } from "@mdi/js";
import DocsTab from "@/components/mapView/DocsTab.vue";
import TasksTab from "@/components/mapView/TasksTab.vue";
import Log from "@/components/tickets/Log.vue";
import GisDataPointFilterDialog from "@/components/list/GisDataPointFilterDialog.vue";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import FilterChoicesDisplay from "@/components/list/FilterChoicesDisplay.vue";
import checkMatch from "@/mixins/checkMatch";
import { db } from "@/mixins/utilisync-db";
import TABS from "@/constants/tabs";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import TableViewDropdown from "@/components/mapView/TableViewDropdown";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "MapViewListView",
  components: {
    DocsTab,
    TasksTab,
    Log,
    GisDataPointFilterDialog,
    FilterChoicesDisplay,
    TableViewDropdown,
  },
  props: {
    view: Object,
    map: Object,
    newSelectedTable: String,
    onMapPage: Boolean,
    bottomPaneHeight: Number,
    progressBarHeight: Number,
    inMapView: Boolean,
    highlightedRowIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mixins: [networkStatusMixin, downloadDataMixin, bulkDownloadDataMixin],
  computed: {
    ...mapGetters(["mapIdSelected", "isRedrawLayers"]),
    nonEmptyFeatureLayerTableHeaders() {
      return this.featureTableHeaders.filter((h) => {
        return Boolean(h.text) && h.text !== "mapServiceId";
      });
    },
    filteredFeatureTableHeaders() {
      return this.featureTableHeaders.filter((h) => {
        return (
          this.featureLayerTableHeadersEnabled[h.value] || h.value === "menu"
        );
      });
    },
    featureTableHeaders() {
      const [feature] = this.selectedFeatureLayerFeatures ?? [];
      if (feature) {
        const fieldHeaders = Object.keys(feature)
          .filter((f) => !["OBJECTID", "GlobalID"].includes(f))
          .map((name) => {
            return {
              text: name,
              align: "start",
              value: name,
              minWidth: "150px",
              width: "180px",
            };
          });
        return [
          {
            text: "Feature ID",
            align: "start",
            value: "OBJECTID",
            minWidth: "150px",
            width: "150px",
          },
          {
            text: "Global ID",
            align: "start",
            value: "GlobalID",
            minWidth: "150px",
            width: "150px",
          },
          ...fieldHeaders,
          {
            text: "",
            align: "start",
            value: "menu",
            sortable: false,
          },
        ];
      }
      return [];
    },
    filteredSelectedFeatureLayerFeatures() {
      return this.selectedFeatureLayerFeatures.filter((g) => {
        if (this.filterChoices.length === 0) {
          return true;
        }

        const isMatch = this.filterChoices.every((fc) => {
          const { selectedField, selectedMatchChoice, selectedMatchValue } = fc;
          return checkMatch({
            val: g[selectedField],
            selectedMatchChoice,
            selectedMatchValue,
          });
        });
        return isMatch;
      });
    },
    gisDataPoints() {
      if (this.selectedLayerType === "F") {
        return this.selectedFeatureLayerFeatures;
      } else if (this.selectedLayerType === "U") {
        return this.mappedGisDataPoints;
      }
      return [];
    },
    selectedFeatureLayerFeatures() {
      const { selectedMapServiceId } = this;
      const { features = [], objectIdFieldName } = this.featureLayerQueryResult;
      return features.map((f) => {
        const mappedAttributesArr = Object.entries(f.attributes).map(
          ([key, val]) => {
            if (Array.isArray(this.getCodeValues(key))) {
              return [
                this.getEsriFieldAlias(key),
                this.getNameForCodeValue(key, val) ?? val,
              ];
            }
            return [this.getEsriFieldAlias(key), val];
          }
        );
        const mappedAttributes = Object.fromEntries(mappedAttributesArr);
        return {
          mapServiceId: selectedMapServiceId,
          OBJECTID: f.attributes[objectIdFieldName],
          ...mappedAttributes,
        };
      });
    },
    selectedLayerType() {
      return this.selectedLayer?.utiliSyncLayerType;
    },
    selectedLayer() {
      const selectedLayer = this.map?.layers?._items?.find((l) => {
        return l.mapServiceId === this.selectedMapServiceId;
      });
      return { ...selectedLayer };
    },
    layerChoices() {
      return this.layers
        .filter((l) => {
          if (this.isOnline) {
            return ["F", "U"].includes(l.service_type);
          } else {
            return ["U"].includes(l.service_type);
          }
        })
        .map((l) => {
          const { service_name: label, map_service_id: value } = l;
          return { label, value };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    mappedGisDataPoints() {
      return [...this.gisDataPointValues].map((g) => {
        const { map_service_id: mapServiceId } = g;
        return { ...g, mapServiceId };
      });
    },
    filteredMappedGisDataPoints() {
      return this.mappedGisDataPoints.filter((g) => {
        if (this.filterChoices.length === 0) {
          return true;
        }

        const isMatch = this.filterChoices.every((fc) => {
          const { selectedField, selectedMatchChoice, selectedMatchValue } = fc;
          return checkMatch({
            val: g[selectedField],
            selectedMatchChoice,
            selectedMatchValue,
          });
        });
        return isMatch;
      });
    },
    headers() {
      const [value = {}] = this.gisDataPointValues;
      const fieldHeaders = Object.keys(value)
        .filter(
          (v) =>
            ![
              "geo_json",
              "object_id",
              "gis_data_point_id",
              "map_service_id",
            ].includes(v)
        )
        .map((name) => {
          return {
            text: name,
            align: "start",
            value: name,
            minWidth: "150px",
            width: "150px",
          };
        });
      return [
        {
          text: "Feature ID",
          align: "start",
          value: "object_id",
          sortable: false,
          minWidth: "120px",
          width: "120px",
        },
        {
          text: "Global ID",
          align: "start",
          value: "gis_data_point_id",
          sortable: false,
          minWidth: "120px",
          width: "120px",
        },
        ...fieldHeaders,
        {
          text: "",
          align: "start",
          value: "menu",
          sortable: false,
        },
      ];
    },
    nonEmptyHeaders() {
      return this.headers.filter((h) => {
        return Boolean(h.text);
      });
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        return this.headersEnabled[h.value] || h.value === "menu";
      });
    },
    isDownloading() {
      const {
        downloadingMaps,
        downloadingMapServices,
        downloadingUsers,
        downloadingFormDefinitions,
        downloadingGisDataFieldOptions,
        downloadingMarkupSymbols,
        downloadingUserDataFields,
        downloadingUserDataValues,
        downloadingTasks,
        downloadingTaskTypes,
      } = this;
      return (
        downloadingMaps ||
        downloadingMapServices ||
        downloadingUsers ||
        downloadingFormDefinitions ||
        downloadingGisDataFieldOptions ||
        downloadingMarkupSymbols ||
        downloadingUserDataFields ||
        downloadingUserDataValues ||
        downloadingTasks ||
        downloadingTaskTypes
      );
    },
  },
  data() {
    return {
      layers: [],
      gisDataPointValues: [],
      selectedMapServiceId: undefined,
      showNotificationPane: false,
      mdiClose,
      mdiFilter,
      mdiPlusCircle,
      mdiRefresh,
      globalId: undefined,
      objectId: undefined,
      showGisInfoPanel: false,
      selectedGisInfo: {},
      gisInfoObjectId: undefined,
      gisInfoTab: undefined,
      showTicketLogTab: false,
      showTasksTab: false,
      showDocsTab: false,
      showLoader: false,
      showGisDataPointFilterDialog: false,
      headersEnabled: {},
      filterChoices: [],
      disableLayerSelect: false,
      featureLayerQueryResult: {},
      featureLayerTableHeadersEnabled: {},
      lastUpdated: new Date(),
      tableHeight: 0,
      featureItemFields: [],
    };
  },
  watch: {
    async isOnline(val) {
      if (val) {
        this.setIsReloadMap(true);
        const selectedMapId = localStorage.getItem("last-map-opened-id");
        this.setSelectedMapId(selectedMapId);
        await this.getAllLayers();
        await this.onLayerIdChange();
      }
    },
    async selectedMapServiceId(val) {
      this.showGisInfoPanel = false;
      this.showFeatureLayerGisInfoPanel = false;
      const [mapService] = await db.mapServices
        .filter((m) => m.map_service_id === val)
        .toArray();
      const mapServiceUrl = mapService?.service_url;
      if (mapServiceUrl) {
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${mapServiceUrl}/query`,
          {
            params: {
              where: "1=1",
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        this.featureItemFields = queryResult?.fields ?? [];
      }
    },
    filterChoices: {
      deep: true,
      handler() {
        this.onResize();
      },
    },
    mapIdSelected: {
      immediate: true,
      async handler(val, oldVal) {
        this.disableLayerSelect = true;
        if (val && oldVal) {
          this.selectedMapServiceId = undefined;
          localStorage.removeItem("selected-map-service-id");
        }
        const [reactiveUtils] = await loadModules(["esri/core/reactiveUtils"]);
        await Promise.all(
          this.map?.layers?.items.map((lv) => {
            return Promise.race([
              reactiveUtils.whenOnce(() => lv?.loadStatus === "loaded"),
              reactiveUtils.whenOnce(() => lv?.loadStatus === "failed"),
            ]);
          }) ?? []
        );
        if (val) {
          this.selectedMapServiceId = undefined;
          await this.getAllLayers();
        }
        await this.$nextTick();
        this.disableLayerSelect = false;
      },
    },
    gisInfoTab(val) {
      this.showTicketLogTab = false;
      this.showTasksTab = false;
      this.showDocsTab = false;
      if (val === TABS.TASKS_TAB) {
        this.showTasksTab = true;
      } else if (val === TABS.TICKET_LOG_TAB) {
        this.showTicketLogTab = true;
      } else if (val === TABS.DOCS_TAB) {
        this.showDocsTab = true;
      }
    },
    nonEmptyHeaders: {
      deep: true,
      handler(val) {
        for (const h of val) {
          if (!["object_id", "gis_data_point_id"].includes(h.value)) {
            this.$set(this.headersEnabled, h.value, true);
          }
        }
      },
    },
    nonEmptyFeatureLayerTableHeaders: {
      deep: true,
      handler(val) {
        for (const h of val) {
          if (!["OBJECTID", "GlobalID"].includes(h.value)) {
            this.$set(this.featureLayerTableHeadersEnabled, h.value, true);
          }
        }
      },
    },
    layerChoices: {
      deep: true,
      handler(val) {
        const selectedMapServiceId = localStorage.getItem(
          "selected-map-service-id"
        );
        const inMap = val.find((l) => l.value === selectedMapServiceId);
        if (selectedMapServiceId && inMap) {
          this.selectedMapServiceId = selectedMapServiceId;
        } else {
          const [layer] = val;
          this.selectedMapServiceId = layer?.value;
        }
      },
    },
    map: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (val) {
          await this.$nextTick();
          await this.onLayerIdChange();
        }
      },
    },
    layers: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          await this.$nextTick();
          await this.onLayerIdChange();
        }
      },
    },
    bottomPaneHeight: {
      immediate: true,
      handler() {
        this.onResize();
      },
    },
  },
  methods: {
    ...mapMutations(["setIsReloadMap", "setSelectedMapId"]),
    getEsriFieldAlias(key) {
      const field = [...this.featureItemFields].find(
        (f) =>
          f.name?.replaceAll(" ", "")?.toLowerCase() ===
          key.replaceAll(" ", "")?.toLowerCase()
      );
      return field?.alias ?? key;
    },
    getRowClass(item) {
      const { selectedMapServiceId } = this;
      const selectedLayer = this.map?.layers?.items?.find((l) => {
        return l.mapServiceId === selectedMapServiceId;
      });

      if (selectedLayer?.utiliSyncLayerType === "U") {
        const { object_id: featureId } = item;
        if (this.highlightedRowIds.includes(featureId)) {
          return "highlighted-row";
        }
      } else if (selectedLayer?.utiliSyncLayerType === "F") {
        const field = selectedLayer?.fields?.find((f) => f.type === "oid");
        const objectIdFieldName = field?.name;
        const { OBJECTID, [objectIdFieldName]: objectId } = item;
        if (
          this.highlightedRowIds.includes(OBJECTID) ||
          this.highlightedRowIds.includes(objectId)
        ) {
          return "highlighted-row";
        }
      }
    },
    getEsriFieldType(esriFieldKey) {
      const field = this.featureLayerQueryResult.fields?.find(
        (f) => f.name === esriFieldKey || f.alias === esriFieldKey
      );
      return field?.type;
    },
    getCodeValues(gisInfoAttributeKey) {
      return this.featureItemFields?.find(
        (f) => f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
      )?.domain?.codedValues;
    },
    getNameForCodeValue(gisInfoAttributeKey, value) {
      return this.featureItemFields
        ?.find(
          (f) =>
            f.name === gisInfoAttributeKey || f.alias === gisInfoAttributeKey
        )
        ?.domain?.codedValues?.find?.((c) => c.code === value)?.name;
    },
    onResize() {
      if (this.inMapView) {
        const height =
          this.bottomPaneHeight -
          this.progressBarHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          20;
        this.tableHeight = Math.max(height, 150);
      } else {
        const height =
          window.innerHeight -
          (this.$refs.filterDisplay?.clientHeight ?? 80) -
          (this.$refs.bottomBar?.clientHeight ?? 40) -
          (this.topBarHeight ?? 56) -
          20;
        this.tableHeight = Math.max(height, 200);
      }
    },
    async reload() {
      this.showLoader = true;
      await this.downloadAuthObject();
      await this.downloadData(true);
      this.setIsReloadMap(true);
      this.showLoader = false;
      this.$emit("reload-finished");
    },
    isRichTextField(inputValue) {
      return Boolean(inputValue?.match?.(/<[^>]*>/g));
    },
    removeFilter(index) {
      this.filterChoices.splice(index, 1);
    },
    onUpdateFilter(choices) {
      this.showGisDataPointFilterDialog = false;
      this.filterChoices = cloneDeep(choices);
    },
    onRowClick(value) {
      this.$emit("row-clicked", value);
    },
    onFeatureRowClick(value) {
      this.$emit("feature-row-clicked", value);
    },
    async getAllLayers() {
      if (!this.mapIdSelected || !this.isOnline) {
        return;
      }
      this.showLoader = true;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/maps/${this.mapIdSelected}`
      );
      this.layers = results.map_services;
      const [firstLayer] = this.layers;
      this.selectedMapServiceId = firstLayer?.map_service_id;
      this.showLoader = false;
    },
    async onLayerIdChange() {
      if (!this.selectedMapServiceId || !this.isOnline) {
        return;
      }
      localStorage.setItem(
        "selected-map-service-id",
        this.selectedMapServiceId
      );
      this.disableLayerSelect = true;
      this.showLoader = true;
      const [reactiveUtils] = await loadModules(["esri/core/reactiveUtils"]);
      await Promise.all(
        this.map?.layers?.items?.map((lv) => {
          return Promise.race([
            reactiveUtils.whenOnce(() => lv?.loadStatus === "loaded"),
            reactiveUtils.whenOnce(() => lv?.loadStatus === "failed"),
          ]);
        }) ?? []
      );
      await this.$nextTick();
      const gisDataFields = await db.gisDataFields.toCollection().toArray();
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataPoints = await db.gisDataPoints
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.gisDataPointValues = gisDataPoints.map((g) => {
        const { object_id: objectId } = g;
        const entries = gisDataFields
          .filter((g) => {
            return g.map_service_id === this.selectedMapServiceId;
          })
          .map((f) => {
            const { name, gis_data_field_id: gisDataFieldId } = f;
            const value = gisDataValues.find((gv) => {
              return (
                gv.gis_data_field_id === gisDataFieldId &&
                gv.feature_id === objectId
              );
            })?.value;
            return [name, value];
          });

        const valueObj = Object.fromEntries(entries);
        return { ...g, ...valueObj };
      });
      if (this.selectedLayer?.utiliSyncLayerType === "F") {
        const {
          data: { results: mapService },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/map_services/${this.selectedLayer.mapServiceId}`
        );
        const mapServiceUrl = mapService?.service_url;
        if (mapServiceUrl) {
          const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
            params: {
              where: "1=1",
              outFields: "*",
              f: "json",
              returnGeometry: false,
              token: localStorage.getItem("esri_token"),
            },
          });
          this.featureLayerQueryResult = data;
        }
      }
      this.disableLayerSelect = false;
      this.showLoader = false;
      this.$emit("layer-changed");
    },
  },
};
</script>

<style>
td * {
  margin: 0 !important;
}

.highlighted-row {
  background-color: rgb(1, 157, 178, 0.3) !important;
}
</style>
