var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", close: "", persistent: "" },
      model: {
        value: _vm.showDeleteFeatureDialog,
        callback: function($$v) {
          _vm.showDeleteFeatureDialog = $$v
        },
        expression: "showDeleteFeatureDialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { id: "deleteFeatureDialog" } },
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _c("h2", { staticClass: "font-weight-regular" }, [
              _vm._v("Delete Feature?")
            ]),
            _c("p", { staticClass: "pt-5" }, [
              _vm._v(
                " This is permanent and cannot be undone. All forms completed from this site will be permanently deleted. "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pa-5 d-flex justify-end gap" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel-confirm-delete")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm-delete")
                    }
                  }
                },
                [_vm._v(" Delete ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }