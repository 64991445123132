<template>
  <v-app-bar class="white--text py-0 my-0 top-bar" height="56px">
    <v-app-bar-nav-icon>
      <v-btn text color="white" @click="toggleMenu(!isMenuOpen)">
        <v-badge
          color="orange"
          :content="numUnsubmittedTickets"
          overlap
          :value="numUnsubmittedTickets > 0"
        >
          <v-icon>{{ mdiMenu }}</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar-nav-icon>

    <v-toolbar-title
      class="d-flex justify-space-between px-3"
      style="width: 100%"
    >
      <v-toolbar-items style="height: calc(56px * 0.9)">
        <div class="d-flex align-end">
          <v-select
            color="white"
            dark
            :items="mapChoices"
            v-model="selectedMapId"
            item-text="label"
            item-value="value"
            @change="onMapIdChange"
            label="Map"
            :style="{
              'max-width': $vuetify.breakpoint.xsOnly ? '180px' : '250px',
            }"
            style="min-width: 70px"
            hide-details
            class="map-dropdown"
          >
          </v-select>
        </div>
      </v-toolbar-items>

      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="white" v-bind="attrs" v-on="on">
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list class="pa-0 ma-0">
            <v-list-item class="py-1 px-4 mx-0" @click="saveMapDefaultExtents">
              <v-icon class="mr-2">
                {{ mdiMap }}
              </v-icon>
              Save Current Extent as Default
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-toolbar-items class="d-flex align-center">
        <v-badge dot color="orange" overlap :value="unreadNotifications">
          <v-icon color="white" @click="$emit('show-notifications')">
            {{ mdiBell }}
          </v-icon>
        </v-badge>
      </v-toolbar-items>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import {
  mdiMenu,
  mdiDotsVertical,
  mdiFormatListCheckbox,
  mdiLayers,
  mdiMap,
  mdiBell,
} from "@mdi/js";
import updateLastMapMixin from "@/mixins/updateLastMapMixin";
import { mapMutations, mapGetters } from "vuex";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "MapViewTopBar",
  computed: {
    ...mapGetters([
      "isMenuOpen",
      "mapIdSelected",
      "numUnsubmittedTickets",
      "unreadNotifications",
    ]),
    mapChoices() {
      return this.maps.map(({ map_id: mapId, name: label }) => ({
        value: mapId,
        label,
      }));
    },
  },
  mixins: [updateLastMapMixin],
  props: {
    maps: Array,
    view: Object,
  },
  data() {
    return {
      mdiMenu,
      mdiDotsVertical,
      mdiFormatListCheckbox,
      mdiLayers,
      mdiMap,
      mdiBell,
      selectedMapId: undefined,
    };
  },
  methods: {
    ...mapMutations([
      "setMenuState",
      "setSelectedMapId",
      "setIsMapChanged",
      "setIsReloadMap",
      "setMyLastMapSet",
    ]),
    async saveMapDefaultExtents() {
      if (!this.selectedMapId) {
        return;
      }

      const map = await this.maps.find((m) => m.map_id === this.selectedMapId);

      const {
        name,
        photo_service_id: photoServiceId,
        sketch_line_service_id: sketchLineServiceId,
        sketch_point_service_id: sketchPointServiceId,
        sketch_poly_service_id: sketchPolyServiceIs,
        current_basemap_service_id: currentBasemapServiceId,
        wkid,
      } = map;
      const { center: mapExtent, zoom: currentZoom } = this.view;
      const payload = {
        name,
        photo_service_id: photoServiceId,
        sketch_line_service_id: sketchLineServiceId,
        sketch_point_service_id: sketchPointServiceId,
        sketch_poly_service_id: sketchPolyServiceIs,
        default_street_basemap_service_id: null,
        default_aerial_basemap_service_id: null,
        center_x_coord: mapExtent?.x,
        center_y_coord: mapExtent?.y,
        zoom_level: currentZoom,
        current_basemap_service_id: currentBasemapServiceId,
        wkid,
      };
      await axiosWithRegularAuth.put(
        `${APIURL}/maps/${this.selectedMapId}`,
        payload
      );
      const newMap = {
        ...map,
        center_x_coord: mapExtent?.x,
        center_y_coord: mapExtent?.y,
        zoom_level: currentZoom,
      };
      await db.maps.put(newMap);
    },
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
    async onMapIdChange() {
      if (navigator.onLine) {
        await axiosWithRegularAuth.post(`${APIURL}/users/setmylastmap`, {
          map_id: this.selectedMapId,
        });
      }
      this.setMyLastMapSet(true);
      localStorage.setItem("last-map-opened-id", this.selectedMapId);
      this.updateAuthObjWithLastMapOpenedId(this.selectedMapId);
      this.setSelectedMapId(this.selectedMapId);
    },
    getSelectedMap() {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const lastMapOpenedId = auth?.last_map_opened;
      localStorage.setItem("last-map-opened-id", lastMapOpenedId);
      this.selectedMapId = localStorage.getItem("last-map-opened-id");
      this.setSelectedMapId(this.selectedMapId);
    },
  },
  async mounted() {
    this.setMyLastMapSet(false);
    await this.getSelectedMap();
  },
  watch: {
    mapIdSelected(val, oldVal) {
      this.selectedMapId = val;
      this.setIsMapChanged(val !== oldVal);
    },
  },
};
</script>

<style>
.map-dropdown.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.map-dropdown.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
